import authservice from "../../api/services/authService.ts";
import { onLoginSuccess, onLogout } from "../../slices/auth.slice.ts";

export function login(loginDetails:any, callback:any) {
    return async function (dispatch: any,getState: any) {
        try {
            let data :any = await authservice.GetToken(loginDetails);
            if(data?.data.token){
                dispatch(onLoginSuccess(data.data));
            }
            else{callback(false)}
           console.log(data);
        } catch(error) {
            callback(false)
            console.log("error");
        }
    };
}

export function resetPassword(loginDetails:any) {
    return async function (dispatch: any,getState: any) {
        try {
            let data :any = await authservice.ResetPassword(loginDetails);
            if(data?.data.token){
                dispatch(onLoginSuccess(data.data));
            }
           console.log(data);
        } catch(error) {
            if(error.response?.status==401){
                dispatch(onLogout());
            }
            console.log("error");
        }
    };
}

export function logout(callback:any) {
    return async function (dispatch: any,getState: any) {
        try {
                dispatch(onLogout());
           //console.log(data);
        } catch(error) {
            callback(false)
            console.log("error");
        }
    };
}