import React, { useEffect, useState } from "react"
import FloatingLabelTextInput from "../../components/floatingtextinput.tsx"
import Joi, { ValidationErrorItem } from "joi";
import { useLocation, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { fetchServiceById, getServiceList } from "../../actions/serviceActionCreator.ts";
import { CommonSelectList, CustomFields, CustomerService, Service } from "../../models/index.ts";
import { fetchCustomFields, fetchCustomerServiceById, saveCustomerService, updateCustomerService } from "../../actions/customerActionCreator.ts";
import DatePicker from "react-datepicker";
import { FileUploader } from "react-drag-drop-files";
import { ToastContainer, toast } from 'react-toastify';
import produce from 'immer';
import Avatar from "react-avatar";
import { BASE_URL } from "../../constants/url.constants.ts";
import moment from "moment";
import axios from 'axios';
interface CustomerServiceEditProp {
    serviceList: Array<CommonSelectList>;
    isAuthenticated: boolean;
    getServiceList: () => void;
    fetchCustomFields: (id: number) => void;
    customFields: Array<CustomFields>;
    saveCustomerService: (data: any, callback: any) => void;
    getServiceById: (id: number) => void;
    service?: CustomerService;
    role:string;
}
const CustomerServiceEdit = (props: CustomerServiceEditProp) => {

    let navigate = useNavigate();

    var intialDetails = {
        serviceId: "1",
        customerName: "",
        customerPhone: "",
        customerEmail: "",
        customerAddress: "",
        accountDebitted: 0,
        payworldDebitted: 0,
        fee: 0,
        serviceCharge: 0,
        paymentType: 0,
        transactionId: "",
        amount: 0,
        customFields: new Array<CustomFields>,
        status: 0
    }

    const [errors, setErrors] = useState<any>(undefined);
    const [customerServiceDetails, setCustomerServiceDetails] = useState(
        intialDetails
    );
    const fileTypes = ["JPG", "PNG", "PDF"];
    const { state } = useLocation();

    const onChange = (
        event:
            | React.ChangeEvent<HTMLInputElement>
            | React.ChangeEvent<HTMLSelectElement>
    ) => {
        const _guardianDetails = {
            ...JSON.parse(JSON.stringify(customerServiceDetails)),
            [event.target.name]: event.target.value,
        };
        setCustomerServiceDetails(_guardianDetails);
        validateFields(_guardianDetails);
    };

    const onChangeCustom = (
        event:
            | React.ChangeEvent<HTMLInputElement>
            | React.ChangeEvent<HTMLSelectElement>, idx: number
    ) => {
        // Deep clone customerServiceDetails
        const _guardianDetails = JSON.parse(JSON.stringify(customerServiceDetails));

        // Update the specific item in customFields array
        _guardianDetails.customFields[idx].value = event.target.value;

        // Update state with the modified _guardianDetails
        setCustomerServiceDetails(_guardianDetails);
        //validateFields(_guardianDetails);
    };
    const onChangeDateFile = (
        event:
            any, idx: number
    ) => {
        const reader = new FileReader();

        reader.onload = () => {
            if (reader.result != null) {
                const base64String = reader.result.toString().split(',')[1]; // extract base64 string
                setCustomerServiceDetails(prevDetails => {
                    const _guardianDetails = deepClone(prevDetails);
                    _guardianDetails.customFields[idx].value = base64String;
                    _guardianDetails.customFields[idx].type = event.name.split('.').pop();

                    return _guardianDetails;
                });
                // Here you can use the base64String as needed (e.g., send to server)
            }
        };

        reader.readAsDataURL(event);

    };

    function deepClone(obj) {
        if (obj === null || typeof obj !== 'object' || obj instanceof File) {
            return obj;
        }

        let clonedObj = Array.isArray(obj) ? [] : {};

        for (let key in obj) {
            if (obj.hasOwnProperty(key)) {
                clonedObj[key] = deepClone(obj[key]);
            }
        }

        return clonedObj;
    }

    const schema = Joi.object().keys({
        customerName: Joi.string().required().label("Enter Name"),
        customerEmail: Joi.string()
            .optional()
            .allow('')
            .email({ tlds: { allow: false } })
            .label("Enter Valid Email"),
        customerPhone: Joi.string()
            .required()
            .label("Enter Valid Phone number"),
        customerAddress: Joi.string()
            .optional()
            .allow(''),
        accountDebitted: Joi.number()
            .optional()
            .allow(''),
        payworldDebitted: Joi.number()
            .optional()
            .allow(''),
        fee: Joi.number()
            .optional()
            .allow(''),
        serviceCharge: Joi.number()
            .optional()
            .allow(''),
        serviceId: Joi.number()
            .optional()
            .allow(''),
        paymentType: Joi.number()
            .optional()
            .allow(''),
        transactionId: Joi.string()
            .optional()
            .allow(''),
        amount: Joi.number()
            .optional()
            .allow(''),
        status: Joi.number()
            .optional()
            .allow(''),
        customFields: Joi.array()
            .optional()
        //.allow(new Array<CustomFields>),
        //.label("Enter Valid Phone number"),

    });
    const validateFields = (requestDetails: any) => {
        setErrors(undefined);
        const result = schema.validate(requestDetails, {
            abortEarly: false,
            allowUnknown: true,
        });
        result.error &&
            result.error.details &&
            setErrorFromJoi(result.error.details);
    };
    const setErrorFromJoi = (validationErrors: Array<ValidationErrorItem>) => {
        let _erroObject = {};
        validationErrors.map((errorItem) => {
            if (errorItem.context && errorItem.context.key) {
                _erroObject = {
                    ..._erroObject,
                    [errorItem.context.key]: errorItem.context.label,
                };
            }
        });
        setErrors(_erroObject);
    };
    useEffect(() => {
        if (!props.isAuthenticated) {
            navigate("/login");
        }
    }, []);

    useEffect(() => {
        props.getServiceList();
    }, [])

    

    useEffect(() => {
        let fee = customerServiceDetails.fee.toString() == "" ? 0 : parseFloat(customerServiceDetails.fee.toString());
        let sercharge = customerServiceDetails.serviceCharge.toString() == "" ? 0 : parseFloat(customerServiceDetails.serviceCharge.toString());
        const _guardianDetails = {
            ...JSON.parse(JSON.stringify(customerServiceDetails)),
            ["amount"]: fee + sercharge,
        };
        setCustomerServiceDetails(_guardianDetails);

    }, [customerServiceDetails.fee, customerServiceDetails.serviceCharge]);

    const onSubmit = async () => {
        const result = schema.validate(customerServiceDetails, {
            abortEarly: false,
            allowUnknown: false,
        });
        if (result.error == undefined) {
            if (state?.id == undefined) {
                if (customerServiceDetails.customFields.filter(it => it.isMandatory && it.value == "").length > 0) {
                    toast.error("Fill all the mandatory fields to proceed");
                    return;
                }
                const formData = new FormData();
                let objects: any = [];
                formData.append("ServiceId", customerServiceDetails.serviceId);
                formData.append("CustomerName", customerServiceDetails.customerName);
                formData.append("CustomerPhone", customerServiceDetails.customerPhone);
                formData.append("CustomerEmail", customerServiceDetails.customerEmail);
                formData.append("CustomerAddress", customerServiceDetails.customerAddress);
                formData.append("Fees", customerServiceDetails.fee.toString());
                formData.append("ServiceCharge", customerServiceDetails.serviceCharge.toString());
                formData.append("AccountDebitted", customerServiceDetails.accountDebitted.toString());
                formData.append("PayworldDebitted", customerServiceDetails.payworldDebitted.toString());
                formData.append("Amount", customerServiceDetails.amount.toString());
                formData.append("PaymentMethod", customerServiceDetails.paymentType.toString());
                formData.append("TransactionId", customerServiceDetails.transactionId);
                formData.append("ServiceStatus", customerServiceDetails.status.toString());
                customerServiceDetails.customFields.forEach(element => {
                    let obj = {
                        "Name": element.name,
                        "CustomFieldType": element.customFieldType,
                        "Value": element.value,
                    }
                    objects.push(obj);
                });
                formData.append("CustomFieldString", JSON.stringify(objects));
                console.log(objects);
                // if(file!=null){
                // formData.append("Photo", file);
                // }
                // let request = {
                //     "Name": employeeDetails.name,
                //     "Email": employeeDetails.email,
                //     "Phone": employeeDetails.phone,
                //     "Joined": joined,
                //     "Photo":file
                // };
                await props.saveCustomerService(
                    formData,
                    callback
                );
            }
            else {
                if (customerServiceDetails.customFields.filter(it => it.isMandatory && it.value == "").length > 0) {
                    toast.error("Fill all the mandatory fields to proceed");
                    return;
                }
                const formData = new FormData();
                let objects: any = [];
                formData.append("Id", state?.id);
                formData.append("ServiceId", customerServiceDetails.serviceId);
                formData.append("CustomerName", customerServiceDetails.customerName);
                formData.append("CustomerPhone", customerServiceDetails.customerPhone);
                formData.append("CustomerEmail", customerServiceDetails.customerEmail);
                formData.append("CustomerAddress", customerServiceDetails.customerAddress);
                formData.append("Fees", customerServiceDetails.fee.toString());
                formData.append("ServiceCharge", customerServiceDetails.serviceCharge.toString());
                formData.append("AccountDebitted", customerServiceDetails.accountDebitted.toString());
                formData.append("PayworldDebitted", customerServiceDetails.payworldDebitted.toString());
                formData.append("Amount", customerServiceDetails.amount.toString());
                formData.append("PaymentMethod", customerServiceDetails.paymentType.toString());
                formData.append("TransactionId", customerServiceDetails.transactionId);
                formData.append("ServiceStatus", customerServiceDetails.status.toString());
                customerServiceDetails.customFields.forEach(element => {
                    let obj = {
                        "Name": element.name,
                        "CustomFieldType": element.customFieldType,
                        "Value": element.value,
                        "FileType": element.type
                    }
                    objects.push(obj);
                });
                formData.append("CustomFieldString", JSON.stringify(objects));
                console.log(objects);
                await props.saveCustomerService(
                    formData,
                    callback
                );
            }
        } else {
            result.error &&
                result.error.details &&
                setErrorFromJoi(result.error.details);
        }
    };

    const isBase64 = (str: any) => {
        if (typeof str !== 'string') {
            return false;
        }
        // Remove data: and whitespace characters in the beginning and padding characters at the end
        str = str.replace(/^data:image\/\w+;base64,/, '');
        str = str.replace(/\s/g, '');
        let chars = str.replace(/[+/=]/g, '');
        let invalidChars = chars.length % 4;

        return invalidChars === 0;
    }

    const callback = (status) => {
        toast("Updated Successfully");
        navigate("/customer-service-index");
    }
    const downloadFile = async (url,name) => {
        try {
            // Create a URL object
            const urlObj = new URL(BASE_URL+url);

            // Extract the pathname (which includes the filename)
            const pathname = urlObj.pathname;

            // Get the filename from the pathname
            const filename = url.substring(url.lastIndexOf('/') + 1);
            var split=filename.split('.');
            const fileType = split[1];
            const outputFileName=customerServiceDetails.customerName.replace(/\s+/g, '-')+'-'+name.replace(/\s+/g, '-')+'.'+fileType;
            axios({
                url: BASE_URL+'customerservice/download?filename='+url,
                method: 'GET',       // Worked using POST or PUT. Prefer POST
                responseType: 'blob', // important
              }).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', outputFileName);
                document.body.appendChild(link);
                link.click();

                // Clean up
            link?.parentNode?.removeChild(link);
            window.URL.revokeObjectURL(url);
              });

            
        } catch (error) {
            console.error('Error downloading the file:', error);
        }
    };
    useEffect(() => {
        if (state?.id != undefined) {
            props.getServiceById(state.id);
        }
    }, [])

    useEffect(() => {
        if (state?.id != undefined && props?.service != undefined) {
            const _guardianDetails = {
                ...JSON.parse(JSON.stringify(customerServiceDetails)),
                serviceId: props.service.serviceId,
                customerName: props.service.customerName,
                customerPhone: props.service.customerPhone,
                customerEmail: props.service.customerEmail ?? '',
                customerAddress: props.service.customerAddress ?? '',
                accountDebitted: props.service.accountDebitted,
                payworldDebitted: props.service.payworldDebitted,
                fee: props.service.fees,
                serviceCharge: props.service.serviceCharge,
                paymentType: props.service.paymentMethod,
                transactionId: props.service.transactionId ?? '',
                amount: props.service.amount,
                customFields: props.service.customFields,
                status: props.service.serviceStatus
            };
            setCustomerServiceDetails(_guardianDetails);
        }
    }, [props?.service])
    if (props.isAuthenticated) {
        return (
            <>
                <div className="studdash mt-4">
                    <div className="container">
                        <div className="card">
                            <h2>Edit Entry</h2>

                            <div className="main-form row">
                                <div className="controls formgroup col-md-6">
                                    <select name="serviceId" className="form-control"
                                        onChange={onChange} disabled
                                        value={customerServiceDetails.serviceId}>
                                        <option value={0}>Select Type</option>
                                        {props.serviceList && props.serviceList.map((item, idx) => (
                                            <option value={item.value}>{item.text}</option>
                                        ))
                                        }
                                    </select>
                                    <label className={'active'}>
                                        Service Type
                                        <i>*</i>
                                    </label>
                                </div>
                                <div className="controls formgroup col-md-6">
                                    <FloatingLabelTextInput
                                        isMandatory
                                        name={"customerName"}
                                        label={"Customer Name"}
                                        onChange={onChange}
                                        value={customerServiceDetails.customerName}
                                        isNumber={false}
                                        disabled
                                    />
                                    {errors && errors.customerName && (
                                        <div className="errors">{errors.customerName}</div>
                                    )}
                                </div>
                                <div className="controls formgroup col-md-6">
                                    <FloatingLabelTextInput
                                        isMandatory
                                        name={"customerPhone"}
                                        label={"Mobile"}
                                        onChange={onChange}
                                        value={customerServiceDetails.customerPhone}
                                        isNumber={false}
                                        disabled
                                    />
                                    {errors && errors.customerPhone && (
                                        <div className="errors">{errors.customerPhone}</div>
                                    )}
                                </div>
                                <div className="controls formgroup col-md-6">
                                    <FloatingLabelTextInput
                                        isMandatory={false}
                                        name={"customerEmail"}
                                        label={"Email"}
                                        onChange={onChange}
                                        value={customerServiceDetails.customerEmail}
                                        isNumber={false}
                                        disabled={!props?.role.includes("Admin")}
                                    />
                                    {errors && errors.customerEmail && (
                                        <div className="errors">{errors.customerEmail}</div>
                                    )}
                                </div>
                                <div className="controls formgroup col-md-6">
                                    <FloatingLabelTextInput
                                        isMandatory={false}
                                        name={"customerAddress"}
                                        label={"Address"}
                                        onChange={onChange}
                                        value={customerServiceDetails.customerAddress}
                                        isNumber={false}
                                        disabled={!props?.role.includes("Admin")}
                                    />
                                    {errors && errors.customerAddress && (
                                        <div className="errors">{errors.customerAddress}</div>
                                    )}
                                </div>
                                <div className="row">
                                    {props.customFields && props.customFields.map((item, idx) => (
                                        <>
                                            {item.customFieldType == 0 && item?.values?.length > 0 && (
                                                <>
                                                    <div className="controls formgroup col-md-6">
                                                        <select className="form-control"
                                                            onChange={e => onChangeCustom(e, idx)}
                                                            value={customerServiceDetails.customFields[idx]?.value}
                                                            disabled={!props?.role.includes("Admin")}
                                                        >
                                                            <option value={0}>Select</option>
                                                            {item.values && item.values?.map((val, idx) => (
                                                                <option value={val}>{val}</option>
                                                            ))
                                                            }
                                                        </select>
                                                        <label className={'active'}>
                                                            {item.name}
                                                            {item.isMandatory && <i>*</i>}
                                                        </label>

                                                    </div>
                                                </>
                                            )}
                                            {item.customFieldType == 0 && item.values.length == 0 && (
                                                <>
                                                    <div className="controls formgroup col-md-6">
                                                        <FloatingLabelTextInput
                                                            isMandatory={item.isMandatory}
                                                            name={item.name}
                                                            label={item.name}
                                                            onChange={e => onChangeCustom(e, idx)}
                                                            value={customerServiceDetails.customFields[idx] ? customerServiceDetails.customFields[idx]?.value : ""}
                                                            isNumber={false}
                                                            disabled={!props?.role.includes("Admin")}
                                                        />

                                                    </div>
                                                </>
                                            )}
                                            {item.customFieldType == 1 && (
                                                <>
                                                    <div className="controls formgroup col-md-6">
                                                        <FloatingLabelTextInput
                                                            isMandatory={item.isMandatory}
                                                            name={item.name}
                                                            label={item.name}
                                                            onChange={e => onChangeCustom(e, idx)}
                                                            value={customerServiceDetails.customFields[idx] ? customerServiceDetails.customFields[idx]?.value : ""}
                                                            isNumber={true}
                                                            disabled={!props?.role.includes("Admin")}
                                                        />

                                                    </div>
                                                </>
                                            )}
                                            {item.customFieldType == 2 && (
                                                <>
                                                    <div className="controls formgroup col-md-6">
                                                        <DatePicker selected={customerServiceDetails.customFields[idx] ? customerServiceDetails.customFields[idx]?.value : new Date()} onChange={(date) => onChangeDateFile(date, idx)} disabled={!props?.role.includes("Admin")} />
                                                        <label className='active'>
                                                            {item.name}
                                                            {item.isMandatory && <i>*</i>}
                                                        </label>
                                                    </div>
                                                </>
                                            )}
                                            {item.customFieldType == 3 && customerServiceDetails.customFields.filter(it => it.value != null && it.value != "" && it.name == item.name).length > 0 && (
                                                <>
                                                    <div className="container">
                                                        <div className="row clearfix">
                                                            <div className="col-md-12 column">
                                                                <table
                                                                    className="table table-bordered table-hover"
                                                                    id="tab_logic"
                                                                >
                                                                    <thead>
                                                                        <tr>
                                                                            <th className="text-center"> Name </th>
                                                                            <th className="text-center"> Actions </th>

                                                                            <th></th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>

                                                                        <tr className="col-md-12" id="addr0">
                                                                            <td className="col-md-6">
                                                                                <input
                                                                                    type="text"
                                                                                    name="Name"
                                                                                    value={item.name}
                                                                                    className="form-control"
                                                                                    disabled
                                                                                />
                                                                            </td>

                                                                            <td className="col-md-6">
                                                                                <button
                                                                                    //className="btn-ico"
                                                                                    style={{ marginRight: 4 }}
                                                                                onClick={() => {
                                                                                    downloadFile(customerServiceDetails.customFields.filter(it => it.name == item.name)[0].value,item.name)
                                                                                }}
                                                                                >
                                                                                    <i className="fas fa-download"></i>
                                                                                </button>

                                                                                <a className="view-photo" target="_blank" href={BASE_URL + customerServiceDetails.customFields.filter(it => it.name == item.name)[0].value} download><i className="fas fa-eye"></i></a>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>

                                                            </div>
                                                        </div>
                                                    </div>

                                                </>
                                            )}
                                            {item.customFieldType == 3 && (
                                                <>
                                                    <div className="col-md-12" style={{ marginTop: 10, marginBottom: 20 }}>
                                                        <FileUploader handleChange={file => onChangeDateFile(file, idx)} name="file" types={fileTypes} label={`Upload or drop a file of ${item.name} here ${item.isMandatory ? '(Mandatory)' : '(Optional)'}`} disabled={!props?.role.includes("Admin")}/>
                                                    </div>
                                                </>
                                            )}

                                        </>
                                    ))}
                                </div>
                                <div className="controls formgroup col-md-12">

                                </div>
                                <div className="controls formgroup col-md-6">
                                    <FloatingLabelTextInput
                                        isMandatory={false}
                                        name={"accountDebitted"}
                                        label={"Account Debitted"}
                                        onChange={onChange}
                                        value={customerServiceDetails.accountDebitted}
                                        isNumber={true}
                                        disabled={!props?.role.includes("Admin")}
                                    />
                                    {errors && errors.accountDebitted && (
                                        <div className="errors">{errors.accountDebitted}</div>
                                    )}
                                </div><div className="controls formgroup col-md-6">
                                    <FloatingLabelTextInput
                                        isMandatory
                                        name={"payworldDebitted"}
                                        label={"Payworld Debitted"}
                                        onChange={onChange}
                                        value={customerServiceDetails.payworldDebitted}
                                        isNumber={true}
                                        disabled={!props?.role.includes("Admin")}
                                    />
                                    {errors && errors.payworldDebitted && (
                                        <div className="errors">{errors.payworldDebitted}</div>
                                    )}
                                </div>
                                <div className="controls formgroup col-md-6">
                                    <FloatingLabelTextInput
                                        isMandatory
                                        name={"fee"}
                                        label={"Fee"}
                                        onChange={onChange}
                                        value={customerServiceDetails.fee}
                                        isNumber={true}
                                        disabled={!props?.role.includes("Admin")}
                                    />
                                    {errors && errors.fee && (
                                        <div className="errors">{errors.fee}</div>
                                    )}
                                </div>
                                <div className="controls formgroup col-md-6">
                                    <FloatingLabelTextInput
                                        isMandatory
                                        name={"serviceCharge"}
                                        label={"Service Charge"}
                                        onChange={onChange}
                                        value={customerServiceDetails.serviceCharge}
                                        isNumber={true}
                                        disabled={!props?.role.includes("Admin")}
                                    />
                                    {errors && errors.serviceCharge && (
                                        <div className="errors">{errors.serviceCharge}</div>
                                    )}
                                </div>
                                <div className="controls formgroup col-md-6">
                                    <FloatingLabelTextInput
                                        isMandatory={false}
                                        name={"amount"}
                                        label={"Total"}
                                        onChange={onChange}
                                        value={customerServiceDetails.amount}
                                        disabled={!props?.role.includes("Admin")}
                                        isNumber={false}
                                    />

                                </div>
                                <div className="controls formgroup col-md-6">
                                    <select name="paymentType" className="form-control"
                                        onChange={onChange}
                                        value={customerServiceDetails.paymentType}
                                        disabled={!props?.role.includes("Admin")}>
                                        <option value={0}>Cash</option>
                                        <option value={1}>Bank Transfer</option>
                                        <option value={2}>UPI</option>
                                    </select>
                                    <label className={'active'}>
                                        Payment Type
                                        <i>*</i>
                                    </label>
                                </div>
                                {customerServiceDetails.paymentType != 0 && (
                                    <div className="controls formgroup col-md-6">
                                        <FloatingLabelTextInput
                                            isMandatory={false}
                                            name={"transactionId"}
                                            label={"TransactionId"}
                                            onChange={onChange}
                                            value={customerServiceDetails.transactionId}
                                            isNumber={false}
                                            disabled={!props?.role.includes("Admin")}
                                        />
                                        {errors && errors.transactionId && (
                                            <div className="errors">{errors.transactionId}</div>
                                        )}

                                    </div>
                                )}
                                <div className="controls formgroup col-md-6">
                                    <select name="status" className="form-control"
                                        onChange={onChange}
                                        value={customerServiceDetails.status}>
                                        <option value={0}>Not Started</option>
                                        <option value={1}>In Progress</option>
                                        <option value={2}>Completed</option>
                                    </select>
                                    <label className={'active'}>
                                        Status
                                        <i>*</i>
                                    </label>
                                </div>
                                {/* <h2>Custom Fields</h2> */}

                                {/* <FileUploader handleChange={handleChange} name="file" types={fileTypes} /> */}
                                <div className="btnwrap">
                                    <button
                                        className="btn"
                                        onClick={() => navigate("/customer-service-index")}
                                    >
                                        {"Cancel"}
                                    </button>
                                    {(
                                    <button
                                        className="btn"
                                        onClick={() => onSubmit()}
                                    >
                                        {"Update"}
                                    </button>
                                    )}
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

const mapDispatchToProps = {
    getServiceList: () => getServiceList(),
    fetchCustomFields: (id: number) => fetchCustomFields(id),
    saveCustomerService: (data: any, callback: any) => updateCustomerService(data, callback),
    getServiceById: (id: number) => fetchCustomerServiceById(id),
}

function mapStateToProps(state: any) {
    return {
        isAuthenticated: state.auth.isAuthenticated,
        service: state.customer.service,
        serviceList: state.service.serviceList,
        customFields: state.service.customFields,
        role:state.auth.role
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(CustomerServiceEdit);