import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { fetchServices } from "../../actions/serviceActionCreator.ts";
import { Service, ServiceList } from "../../models";
import ReactPaginate from "react-paginate";

interface IServiceProps {
    isAuthenticated?: boolean;
    role:string;
    getServices: (page: number, searchText: string) => void;
    services?: ServiceList;
}
const ServiceIndex = (props: IServiceProps) => {
    const [count, setCount] = useState(10);
    const [page, setPage] = useState(1);
    const [searchText, setSearchText] = useState("");
    let navigate = useNavigate();
    useEffect(()=>{
        if(!props.isAuthenticated){
            navigate("/login");
        }
        else if(!props.role?.includes("Admin")){
            navigate("/");
        }
    },[]);
    const submit = async () => {
        navigate("/service-create");
    };
    const columns = [
        {
            label: "Name",
            prop: "name",
        },
        {
            label: "Fee",
            prop: "fee",
        },
        {
            label: "Service Charge",
            prop: "serviceCharge",
        },
    ];
    const handlePageClick = (data: any) => {
        setPage(++data.selected);
    };

    useEffect(() => {
        props.getServices(page, searchText);
    }, [page]);
    
    if (props.isAuthenticated) {
        return (
            <>
                <main className="">
                    <div className="studdash mt-4">
                        <div className="container">
                            <div className="card">
                                <div className="content-filter">
                                    <h2>Service Types</h2>
                                    <div className="d-flex">
                                        <a
                                            className="btn"
                                            href="javascript:void(0)"
                                            onClick={() => submit()}
                                        >
                                            Add New
                                        </a>
                                    </div>
                                </div>

                                <div className="mec-table">
                                    <table>
                                        <thead>
                                            <tr role="row">
                                                {columns
                                                    ? columns.map((c: any) => {
                                                        return <th>{c.label}</th>;
                                                    })
                                                    : null}
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        {props.services?.data ? (
                                            props.services?.data.length > 0 ? (
                                                <tbody id="filterinfo">
                                                    {props.services
                                                        ? props.services?.data.map(
                                                            (c: Service) => {
                                                                return (
                                                                    <>
                                                                        <tr>
                                                                            <td>{c.name}</td>
                                                                            <td>
                                                                                {c.fees}
                                                                            </td>
                                                                            <td>{c.serviceCharge}</td>
                                                                            <td className="more">
                                                                                <h5 className="actions">

                                                                                    <button
                                                                                        className="btn-ico"
                                                                                        onClick={() => {
                                                                                            navigate(
                                                                                                "/service-create", { state: { id: c.id } }
                                                                                            );
                                                                                        }}
                                                                                    >
                                                                                        <i className="fas fa-edit"></i>
                                                                                    </button>

                                                                                </h5>
                                                                            </td>
                                                                        </tr>
                                                                    </>
                                                                );
                                                            }
                                                        )
                                                        : null}
                                                </tbody>
                                            ) : (
                                                <p>No Data Found</p>
                                            )
                                        ) : null}
                                    </table>
                                </div>
                                {props.services
                                    ? props.services?.totalRecords
                                        ? props.services?.totalRecords > count && (
                                            <ReactPaginate
                                                previousLabel={"←"}
                                                nextLabel={"→"}
                                                breakLabel={"..."}
                                                breakClassName={"break-me"}
                                                pageCount={
                                                    props.services
                                                        ? Math.ceil(props.services.totalRecords / 10)
                                                        : 1
                                                }
                                                marginPagesDisplayed={1}
                                                pageRangeDisplayed={2}
                                                onPageChange={handlePageClick}
                                                containerClassName={"pagination"}
                                                activeClassName={"active"}
                                            />
                                        )
                                        : null
                                    : null}
                            </div>
                        </div>
                    </div>
                </main>
            </>
        )
    }
}

const mapDispatchToProps = {
    getServices: (page: number, serachText: string) => fetchServices(page, serachText)
}

function mapStateToProps(state: any) {
    return {
        services: state.service?.services,
        isAuthenticated:state.auth.isAuthenticated,
        role:state.auth.role
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ServiceIndex)