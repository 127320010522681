import { createSlice } from "@reduxjs/toolkit";
import { EmployeeModel, Service, ServiceModal } from "../models";

const serviceState: EmployeeModel = {
    employees:undefined
};
const serviceSlice = createSlice({
    name: "employee",
    initialState: serviceState,
    reducers: {
        onFetchEmployees: (state, action: { payload: any; }) => {
            state.employees=action.payload;
        },
        onFetchEmployeeByIdSuccess: (state, action: { payload: any; }) => {
            state.employee=action.payload;
        },
    },
});

export const { 
    onFetchEmployees,
    onFetchEmployeeByIdSuccess
 } = serviceSlice.actions;

const employeeReducer = serviceSlice.reducer;

export type employeeState = ReturnType<typeof employeeReducer>

export default employeeReducer;