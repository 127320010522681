import employeeService from "../api/services/employeeService.ts";
import EmployeeService from "../api/services/employeeService.ts";
import { onLogout } from "../slices/auth.slice.ts";
import { onFetchEmployeeByIdSuccess, onFetchEmployees } from "../slices/employee.slice.ts";
import { onFetchServiceByIdSuccess, onFetchServices } from "../slices/service.slice.ts";

export function fetchEmployees(page:number,searchText:string) {
    return async function (dispatch: any,getState: any) {
        try {
            let data = await EmployeeService.GetEmployees({page,searchText});
            if (data.status === 200) {
                console.log(data.data.data);
                dispatch(onFetchEmployees(data.data));
            }
        } catch(error) {
            if(error.response?.status==401){
                dispatch(onLogout());
            }
            console.log("error");
        }
    };
}

export function saveEmployee(details:any,callback:any) {
    return async function (dispatch: any,getState: any) {
        try {
            let data = await employeeService.SaveEmployee(details);
            if (data.status === 200) {
                console.log(data.data.data);
                callback();
            }
        } catch(error) {
            if(error.response?.status==401){
                dispatch(onLogout());
            }
            console.log("error");
        }
    };
}

export function fetchEmployeeById(id:number) {
    return async function (dispatch: any,getState: any) {
        try {
            let data = await employeeService.GetemployeeById(id);
            if (data.status === 200) {
                console.log(data.data.data);
                dispatch(onFetchEmployeeByIdSuccess(data.data));
            }
        } catch(error) {
            if(error.response?.status==401){
                dispatch(onLogout());
            }
            console.log("error");
        }
    };
}

export function updateEmployee(details:any,callback:any) {
    return async function (dispatch: any,getState: any) {
        try {
            let data = await employeeService.UpdateEmployee(details);
            if (data.status === 200) {
                console.log(data.data.data);
                callback();
            }
        } catch(error) {
            if(error.response?.status==401){
                dispatch(onLogout());
            }
            console.log("error");
        }
    };
}