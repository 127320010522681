import React, { useEffect, useState } from "react";
import { useRef } from "react";
import FloatingLabelTextInput from "../../components/floatingtextinput.tsx";
import Joi, { ValidationErrorItem } from "joi";
import { connect } from "react-redux";
import { fetchServiceById, getCustomValues, saveCustomValues, saveService, updateService } from "../../actions/serviceActionCreator.ts";
import { useLocation, useNavigate } from "react-router-dom";
import { Service } from "../../models/index.ts";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Modal from 'react-modal';

interface ServiceDetails {
  name?: string;
  description?: string;
  saveService: (data: any, callback: any) => void;
  fetchService: (id: number) => void;
  service?: Service;
  updateService: (data: any, callback: any) => void;
  customValues?: Array<string>;
  getCustomValues: (id: number) => void;
  saveCustomValues:(data:any,callback:any)=>void;
  isAuthenticated:boolean;
    role:string;
}
const ServiceCreate = (props: ServiceDetails) => {
  let navigate = useNavigate();
  const { state } = useLocation();

  const myRef = useRef<any>(null);
  const [rows, setRows] = useState<any>([]);
  const [errors, setErrors] = useState<any>(undefined);
  const [heading, setHeading] = useState("Configure Service");
  const [buttonText, setButtonText] = useState("Save");
  const [isOpen, setIsOpen] = useState(false);
  const [values, setValues] = useState<any>([""]);
  const [customField, setCustomField] = useState("0");

  var intialDetails = {
    name: "",
    description: "",
    fees: 0,
    serviceCharge: 0
  }
  const [serviceDetails, setServiceDetails] = useState(
    intialDetails
  );

  useEffect(()=>{
    if(!props.isAuthenticated){
        navigate("/login");
    }
    else if(!props.role?.includes("Admin")){
        navigate("/");
    }
},[]);
  useEffect(() => {
    if (state?.id != undefined) {
      props.fetchService(state.id);
      setHeading("Update Service");
      setButtonText("Update");
    }
  }, [])
  useEffect(() => {
    if (state?.id != undefined && props?.service != undefined) {
      let customFields: any = [];
      var editDetails = {
        name: props?.service.name,
        description: props?.service?.description,
        fees: props?.service?.fees,
        serviceCharge: props?.service?.serviceCharge
      };
      props?.service?.customFields?.forEach(element => {
        let obj = {
          "Id": element.id,
          "Name": element.name,
          "Type": element.customFieldType,
          "IsMandatory": element.isMandatory
        };
        customFields.push(obj);
      });
      setRows(customFields);
      setServiceDetails(editDetails);
    }
  }, [props?.service])
  const onChange = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
  ) => {
    const _guardianDetails = {
      ...JSON.parse(JSON.stringify(serviceDetails)),
      [event.target.name]: event.target.value,
    };
    setServiceDetails(_guardianDetails);
    validateFields(_guardianDetails);
  };

  const handleChange = (idx, e) => {
    const { name, value } = e.target;
    const rowss = [...rows];
    rowss[idx][name] = value;
    //const newRow=[...rows,rowss]
    setRows(
      rowss
    );
  };
  const checkHandler = (idx, e) => {
    const { name, value } = e.target;
    const rowss = [...rows];
    rowss[idx][name] = !rowss[idx][name];
    //const newRow=[...rows,rowss]
    setRows(
      rowss
    );
  }
  const handleAddRow = () => {
    const item = {
      Name: "",
      Type: 1,
      IsMandatory: false
    };
    let ro = [...rows, item];
    setRows(
      ro
    );
  };
  const handleRemoveRow = () => {
    let ro = [...rows];
    ro.pop();
    setRows(
      ro
    );
  };
  const handleRemoveSpecificRow = (idx) => {
    const rowss = [...rows]
    rowss.splice(idx, 1)
    setRows(rowss);
  }
  const handleChangeCustom = (idx, e) => {
    const { value } = e.target;
    const rowss = [...values];
    rowss[idx] = value;
    //const newRow=[...rows,rowss]
    setValues(
      rowss
    );
  };
  const handleRemoveCustomValue = (idx) => {
    const rowss = [...values]
    rowss.splice(idx, 1)
    setValues(rowss);
  }
  const handleAddCustomValue = () => {
    const item = "";
    let ro = [...values, item];
    setValues(
      ro
    );
  };

  const saveCustomValues=async()=>{
    let isValid=true;
    values.forEach(element => {
      if(element==""){
        isValid=false;
        return;
      }
    });
    if(isValid && customField!="0"){
      let request = {
        "Id": parseInt(customField),
        "Values": values,
      };
      await props.saveCustomValues(
        request,
        saveCustomCallback
      );
    }
    else{
      toast.error("Enter all values");
    }
  }

  const saveCustomCallback=()=>{
    toast.success("Saved successfully");
    let val=[""];
    setValues(val);
    setCustomField("0");
    setIsOpen(false);
  }

  const schema = Joi.object().keys({
    name: Joi.string().required().label("Enter Name"),
    fees: Joi.number()
      .greater(-1)
      .required()
      .label("Enter Valid Fees"),
    serviceCharge: Joi.number()
      .greater(-1)
      .required()
      .label("Enter Valid Service Charge"),
    description: Joi.optional(),
  });
  const validateFields = (requestDetails: any) => {
    setErrors(undefined);
    const result = schema.validate(requestDetails, {
      abortEarly: false,
      allowUnknown: true,
    });
    result.error &&
      result.error.details &&
      setErrorFromJoi(result.error.details);
  };
  const setErrorFromJoi = (validationErrors: Array<ValidationErrorItem>) => {
    let _erroObject = {};
    validationErrors.map((errorItem) => {
      if (errorItem.context && errorItem.context.key) {
        _erroObject = {
          ..._erroObject,
          [errorItem.context.key]: errorItem.context.label,
        };
      }
    });
    setErrors(_erroObject);
  };
  const onSubmit = async () => {
    const result = schema.validate(serviceDetails, {
      abortEarly: false,
      allowUnknown: false,
    });
    if (result.error == undefined) {
      var customFields: any = [];
      rows.forEach(element => {
        let obj = {};
        obj["Name"] = element.Name;
        obj["CustomFieldType"] = parseInt(element.Type);
        obj["IsMandatory"] = element.IsMandatory;
        customFields.push(obj);
      });
      if (state?.id == undefined) {
        let request = {
          "Name": serviceDetails.name,
          "Fees": serviceDetails.fees,
          "ServiceCharge": serviceDetails.serviceCharge,
          "Description": serviceDetails.description,
          "CustomFields": customFields
        };
        await props.saveService(
          request,
          callback
        );
      }
      else {
        let request = {
          "Id": state.id,
          "Name": serviceDetails.name,
          "Fees": serviceDetails.fees,
          "ServiceCharge": serviceDetails.serviceCharge,
          "Description": serviceDetails.description,
          "CustomFields": customFields
        };
        await props.updateService(
          request,
          callback
        );
      }
    } else {
      result.error &&
        result.error.details &&
        setErrorFromJoi(result.error.details);
    }
  };

  const callback = (status) => {
    toast("Saved Successfully");
    navigate("/service-index");
  }
  const customStyles = {
    content: {
      marginRight: "-50%",
      transform: "translate(-50%, -10%)",
      width: "50%",
    },
  };

  useEffect(() => {
    if (customField != "0") {
      props.getCustomValues(parseInt(customField));
    }
    else{
      setValues([""]);
    }
  }, [customField])
  useEffect(() => {
    if (customField != "0" && props?.customValues!=undefined && props.customValues.length>0) {
      setValues(props.customValues);
    }
    else{
      setValues([""]);
    }
  }, [props?.customValues])
  return (
    <>
      <div className="studdash mt-4">
        <div className="container">
          <div className="card">
            <h2>{heading}</h2>

            <div className="main-form row">
              <div className="controls formgroup col-md-6">
                <FloatingLabelTextInput
                  isMandatory
                  name={"name"}
                  label={"Name"}
                  onChange={onChange}
                  value={serviceDetails.name}
                  isNumber={false}
                />
                {errors && errors.name && (
                  <div className="errors">{errors.name}</div>
                )}
              </div>
              <div className="controls formgroup col-md-6">
                <FloatingLabelTextInput
                  isMandatory
                  name={"fees"}
                  label={"Fees"}
                  onChange={onChange}
                  value={serviceDetails.fees}
                  isNumber={true}
                />
                {errors && errors.fees && (
                  <div className="errors">{errors.fees}</div>
                )}
              </div>
              <div className="controls formgroup col-md-6">
                <FloatingLabelTextInput
                  isMandatory
                  name={"serviceCharge"}
                  label={"Service Charge"}
                  onChange={onChange}
                  value={serviceDetails.serviceCharge}
                  isNumber={true}
                />
                {errors && errors.serviceCharge && (
                  <div className="errors">{errors.serviceCharge}</div>
                )}
              </div>
              <div className="controls formgroup col-md-6">
                <FloatingLabelTextInput
                  isMandatory={false}
                  name={"description"}
                  label={"Description"}
                  onChange={onChange}
                  value={serviceDetails.description}
                  isNumber={false}
                />
                {errors && errors.description && (
                  <div className="errors">{errors.description}</div>
                )}
              </div>

              <div className="container">
                <div className="row clearfix">
                  <div className="col-md-12 column">
                    <table
                      className="table table-bordered table-hover"
                      id="tab_logic"
                    >
                      <thead>
                        <tr>
                          <th className="text-center"> Name </th>
                          <th className="text-center"> Type </th>
                          <th className="text-center"> IsMandatory </th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {rows && rows.map((item, idx) => (
                          <tr id="addr0" key={idx}>
                            <td>
                              <input
                                type="text"
                                name="Name"
                                value={rows[idx].Name}
                                onChange={(event) => handleChange(idx, event)}
                                className="form-control"
                              />
                            </td>
                            <td>
                              <select name="Type" className="form-control"
                                onChange={(event) => handleChange(idx, event)}
                                value={rows[idx].Type}>
                                <option value={0}>String</option>
                                <option value={1}>Number</option>
                                <option value={2}>Date</option>
                                <option value={3}>File</option>
                              </select>
                            </td>
                            <td>
                              <input
                                type="checkbox"
                                name="IsMandatory"
                                value={rows[idx].IsMandatory}
                                checked={rows[idx].IsMandatory}
                                onChange={(event) => checkHandler(idx, event)}
                                className=""
                              />
                            </td>
                            <td>
                              <button
                                className="btn-ico"
                                onClick={() => {
                                  handleRemoveSpecificRow(idx)
                                }}
                              >
                                <i className="fas fa-trash"></i>
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <button
                      onClick={handleAddRow}
                      className="btn btn-default pull-left"
                    >
                      Add Row
                    </button>
                    <button
                      onClick={handleRemoveRow}
                      className="pull-right btn btn-default"
                    >
                      Delete Row
                    </button>
                  </div>
                </div>
              </div>

              <div className="btnwrap">
                {state?.id!=undefined&&(
                <button
                  className="btn btn-border"
                  onClick={() => setIsOpen(!isOpen)}
                >
                  {"Predefine Custom Fields"}
                </button>
                )}
                <button
                  className="btn"
                  onClick={() => navigate("/service-index")}
                >
                  {"Cancel"}
                </button>
                <button
                  className="btn"
                  onClick={() => onSubmit()}
                >
                  {buttonText}
                </button>
                {/* <input
                  className="submit btn btn-main"
                  id="validateIt"
                  type="submit"
                  value={buttonText}
                  onClick={() => onSubmit()}
                ></input> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={isOpen}
        //onAfterOpen={afterOpenModal}
        //onRequestClose={closeModal}
        style={customStyles}

        contentLabel="Example Modal"
      >
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel">
            Custom Field
          </h5>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={() => setIsOpen(false)}
          ></button>
        </div>
        <div className="modal-body addmod_popup">
          <div className="main-form row" style={{maxHeight:350}}>
            <div className="controls formgroup col-md-12">
              <select name="Field" className="form-control"
                onChange={(event) => setCustomField(event.target.value)}
                value={customField}>
                <option value={0}>Select Field</option>
                {rows && rows.map((item, idx) => (
                  <option value={rows[idx].Id}>{rows[idx].Name}</option>
                ))
                }
              </select>
            </div>
            {values && values.map((item, idx) => (
              <div className="row">
              <div className="controls formgroup col-md-9">
                <input
                  type="text"
                  name="Name"
                  value={item}
                  onChange={(event) => handleChangeCustom(idx, event)}
                  className="form-control"
                />
              </div>
              {values.length == idx + 1 ? (
                  <>
                    <div className="col-md-3">
                      <button
                        className="btn-ico"
                        onClick={() => {
                          handleAddCustomValue()
                        }}
                      >
                        <i className="fa fa-plus"></i>
                      </button>
                    </div>
                  </>
                ) : <div className="col-md-3">
                <button
                  className="btn-ico"
                  onClick={() => {
                    handleRemoveCustomValue(idx)
                  }}
                >
                  <i className="fa fa-trash"></i>
                </button>
              </div>
                      }
              </div>
            ))
                }
                
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-border"
                data-bs-dismiss="modal"
                onClick={() => setIsOpen(false)}
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-main"
                onClick={() => saveCustomValues()}
              >
                Save
              </button>
            </div>
          </div>
        </div>

      </Modal>
    </>
  )
}

const mapDispatchToProps = {
  saveService: (data: any, callback: any) => saveService(data, callback),
  fetchService: (id: number) => fetchServiceById(id),
  updateService: (data: any, callback: any) => updateService(data, callback),
  getCustomValues: (id: number) => getCustomValues(id),
  saveCustomValues:(data:any,callback:any)=>saveCustomValues(data,callback)
}

function mapStateToProps(state: any) {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    service: state.service.service,
    customValues: state.service.customValues,
    role:state.auth.role
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ServiceCreate);