import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import storage from 'redux-persist/lib/storage'
import rootReducer from "../slices/root.reducer.ts";
import { persistReducer } from 'redux-persist'


const middleware = [
    ...getDefaultMiddleware({
        immutableCheck: false
    }),
    /*YOUR CUSTOM MIDDLEWARES HERE*/
];
const persistConfig = {
    key: 'root',
    storage
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
    reducer: persistedReducer,
    middleware,
});

export default store;