import customerService from "../api/services/customerService.ts";
import { onLogout } from "../slices/auth.slice.ts";
import { onFetchCashBoxDetails, onFetchCustomerServiceByIdSuccess, onFetchPendingServiceHistory, onFetchServiceDetails, onFetchServiceHistory } from "../slices/customer.slice.ts";
import { onFetchCustomFields, onFetchServiceByIdSuccess } from "../slices/service.slice.ts";

export function fetchServiceHistory(page:number,searchText:string) {
    return async function (dispatch: any,getState: any) {
        try {
            let data = await customerService.GetServiceHistory({page,searchText});
            if (data.status === 200) {
                console.log(data.data.data);
                dispatch(onFetchServiceHistory(data.data));
            }
        } catch(error) {
            if(error.response?.status==401){
                dispatch(onLogout());
            }
            console.log("error");
        }
    };
}

export function fetchPendingServiceHistory(page:number,searchText:string) {
    return async function (dispatch: any,getState: any) {
        try {
            let data = await customerService.GetPendingServiceHistory({page,searchText});
            if (data.status === 200) {
                console.log(data.data.data);
                dispatch(onFetchPendingServiceHistory(data.data));
            }
        } catch(error) {
            if(error.response?.status==401){
                dispatch(onLogout());
            }
            console.log("error");
        }
    };
}

export function fetchCustomFields(id:number) {
    return async function (dispatch: any,getState: any) {
        try {
            let data = await customerService.GetCustomFields(id);
            if (data.status === 200) {
                console.log(data.data.data);
                dispatch(onFetchCustomFields(data.data));
            }
        } catch(error) {
            if(error.response?.status==401){
                dispatch(onLogout());
            }
            console.log("error");
        }
    };
}

// export function getServices() {
//     return async function (dispatch: any,getState: any) {
//         try {
//             let data = await customerService.gets(details);
//             if (data.status === 200) {
//                 console.log(data.data.data);
//                 callback();
//             }
//         } catch(error) {
//             console.log("error");
//         }
//     };
// }

export function fetchCustomerServiceById(id:number) {
    return async function (dispatch: any,getState: any) {
        try {
            let data = await customerService.GetServiceById(id);
            if (data.status === 200) {
                console.log(data.data.data);
                dispatch(onFetchCustomerServiceByIdSuccess(data.data));
            }
        } catch(error) {
            if(error.response?.status==401){
                dispatch(onLogout());
            }
            console.log("error");
        }
    };
}

export function saveCustomerService(details:any,callback:any) {
    return async function (dispatch: any,getState: any) {
        try {
            let data = await customerService.SaveCustomerService(details);
            if (data.status === 200) {
                console.log(data.data.data);
                callback();
            }
        } catch(error) {
            if(error.response?.status==401){
                dispatch(onLogout());
            }
            console.log("error");
        }
    };
}
export function updateCustomerService(details:any,callback:any) {
    return async function (dispatch: any,getState: any) {
        try {
            let data = await customerService.UpdateCustomerService(details);
            if (data.status === 200) {
                console.log(data.data.data);
                callback();
            }
        } catch(error) {
            if(error.response?.status==401){
                dispatch(onLogout());
            }
            console.log("error");
        }
    };
}

export function fetchCashBoxDetails(center:number) {
    return async function (dispatch: any,getState: any) {
        try {
            let data = await customerService.GetCashbox(center);
            if (data.status === 200) {
                //console.log(data.data.data);
                dispatch(onFetchCashBoxDetails(data.data));
            }
        } catch(error) {
            if(error.response?.status==401){
                dispatch(onLogout());
            }
            console.log("error");
        }
    };
}

export function resetCashBox(details:any,callback:any) {
    return async function (dispatch: any,getState: any) {
        try {
            let data = await customerService.ResetCashbox(details);
            if (data.status === 200) {
                console.log(data.data.data);
                callback();
            }
        } catch(error) {
            if(error.response?.status==401){
                dispatch(onLogout());
            }
            console.log("error");
        }
    };
}

export function fetchServiceDetails(id:number) {
    return async function (dispatch: any,getState: any) {
        try {
            let data = await customerService.GetrServiceCharge(id);
            if (data.status === 200) {
                //console.log(data.data.data);
                dispatch(onFetchServiceDetails(data.data));
            }
        } catch(error) {
            if(error.response?.status==401){
                dispatch(onLogout());
            }
            console.log("error");
        }
    };
}