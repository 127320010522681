import apiService, { ApiService } from "../api-service.ts";

class AuthService {
    /**
     * api service helper.
     */
    private _apiService: ApiService;

    /**
     * instaniates AuditService;
     */
    constructor() {
        this._apiService = apiService;
    }

    public GetToken(loginDetails:any) {
        return this._apiService.post(`login/token`,loginDetails);
    }

    public ResetPassword(data:any) {
        return this._apiService.post(`login/resetPassword`,data);
    }
}

export default new AuthService();
