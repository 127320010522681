import { createSlice } from "@reduxjs/toolkit";
import { AuthDetails } from "../models/index";
import jwt from 'jwt-decode';

// test STATE
const authState: AuthDetails = {
    isAuthenticated:undefined,
    userName:undefined
};

const authSlice = createSlice({
    name: "auth",
    initialState: authState,
    reducers: {
        onLoginSuccess: (state, action: { payload: any; }) => {
            const tokenData:any=jwt(action.payload.token);
            state.token=action.payload.token;
            state.userName=tokenData["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress"];
            state.firstName=tokenData["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"];
            state.role=tokenData["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"];
            state.userId=tokenData["http://schemas.microsoft.com/ws/2008/06/identity/claims/userdata"];
            state.center=tokenData["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/postalcode"];
            state.profile=tokenData["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/otherphone"];
            //state.testData = action.payload;
            state.isAuthenticated=true;
            localStorage.setItem('token', action.payload.token);
            localStorage.setItem('refresh_token', action.payload.refresh_token)
        },
        onLogout:(state)=>{
            state.isAuthenticated=false;
            localStorage.setItem('token', "");
            localStorage.setItem('refresh_token', "")
        }
    },
});

export const { 
    onLoginSuccess,
    onLogout
 } = authSlice.actions;

const authReducer = authSlice.reducer;

export type authState = ReturnType<typeof authReducer>

export default authReducer;