import { connect } from "react-redux"
import React, { useEffect, useState } from "react"
import { Employee, EmployeeList } from "../../models";
import { useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { fetchEmployees } from "../../actions/employeeActionCreator.ts";
import moment from 'moment';

interface EmployeeCreateProps{
    isAuthenticated:boolean;
    role:string;
    employees:EmployeeList,
    getEmployees:(page:number,searchText:string)=>void;
}
const EmployeeIndex=(props:EmployeeCreateProps)=>{
    const [count, setCount] = useState(10);
    const [page, setPage] = useState(1);
    const [searchText, setSearchText] = useState("");
    let navigate = useNavigate();
    useEffect(()=>{
        if(!props.isAuthenticated){
            navigate("/login");
        }
        else if(!props.role?.includes("Admin")){
            navigate("/");
        }
    },[]);
    const submit = async () => {
        navigate("/employee-create");
    };
    const columns = [
        {
            label: "Name",
            prop: "name",
        },
        {
            label: "Email",
            prop: "email",
        },
        {
            label: "Joined On",
            prop: "joined",
        },
        {
            label: "Status",
            prop: "status",
        },
        {
            label: "Center",
            prop: "center",
        },
    ];
    const handlePageClick = (data: any) => {
        setPage(++data.selected);
    };

    useEffect(() => {
        props.getEmployees(page, searchText);
    }, [page]);
    if (props.isAuthenticated) {
        return (
            <>
                <main className="">
                    <div className="studdash mt-4">
                        <div className="container">
                            <div className="card">
                                <div className="content-filter">
                                    <h2>Employees</h2>
                                    <div className="d-flex">
                                        <a
                                            className="btn"
                                            href="javascript:void(0)"
                                            onClick={() => submit()}
                                        >
                                            Add New
                                        </a>
                                    </div>
                                </div>

                                <div className="mec-table">
                                    <table>
                                        <thead>
                                            <tr role="row">
                                                {columns
                                                    ? columns.map((c: any) => {
                                                        return <th>{c.label}</th>;
                                                    })
                                                    : null}
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        {props.employees?.data ? (
                                            props.employees?.data.length > 0 ? (
                                                <tbody id="filterinfo">
                                                    {props.employees
                                                        ? props.employees?.data.map(
                                                            (c: Employee) => {
                                                                return (
                                                                    <>
                                                                        <tr>
                                                                            <td>{c.name}</td>
                                                                            <td>
                                                                                {c.email}
                                                                            </td>
                                                                            <td>{moment(c.joined).format("DD MMM YYYY")}</td>
                                                                            <td>
                                                                                {c.status==0?'Active':c.status==1?'Resigned':'Terminated'}
                                                                            </td>
                                                                            <td>
                                                                                {c.center==0?'Thodannur':c.center==1?'Cherandathur':'Thurasheri Kadav'}
                                                                            </td>
                                                                            <td className="more">
                                                                                <h5 className="actions">

                                                                                    <button
                                                                                        className="btn-ico"
                                                                                        onClick={() => {
                                                                                            navigate(
                                                                                                "/employee-edit", { state: { id: c.id } }
                                                                                            );
                                                                                        }}
                                                                                    >
                                                                                        <i className="fas fa-edit"></i>
                                                                                    </button>

                                                                                </h5>
                                                                            </td>
                                                                        </tr>
                                                                    </>
                                                                );
                                                            }
                                                        )
                                                        : null}
                                                </tbody>
                                            ) : (
                                                <p>No Data Found</p>
                                            )
                                        ) : null}
                                    </table>
                                </div>
                                {props.employees
                                    ? props.employees?.totalRecords
                                        ? props.employees?.totalRecords > count && (
                                            <ReactPaginate
                                                previousLabel={"←"}
                                                nextLabel={"→"}
                                                breakLabel={"..."}
                                                breakClassName={"break-me"}
                                                pageCount={
                                                    props.employees
                                                        ? Math.ceil(props.employees.totalRecords / 10)
                                                        : 1
                                                }
                                                marginPagesDisplayed={1}
                                                pageRangeDisplayed={2}
                                                onPageChange={handlePageClick}
                                                containerClassName={"pagination"}
                                                activeClassName={"active"}
                                            />
                                        )
                                        : null
                                    : null}
                            </div>
                        </div>
                    </div>
                </main>
            </>
        )
    }
}

const mapDispatchToProps={
    getEmployees:(page:number,searchText:string)=>fetchEmployees(page,searchText)
}

function mapStateToProps(state:any){
    return{
        isAuthenticated:state.auth.isAuthenticated,
        employees:state.employee.employees,
        role:state.auth.role
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(EmployeeIndex)