import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { login, logout } from "../../actions/auth/authActionCreator.ts";
import { LoginDetails } from "../../models";
import React from "react";

interface LoginProps {
  userLogin: any;
  isAuthenticated: boolean;
  role: string;
  logout:()=>void;
}
const Login = (props: LoginProps) => {
  let navigate = useNavigate();
  const [isLoading, showLoading] = useState(false);
  useEffect(() => {
    if (props.isAuthenticated) {
      showLoading(false);
        navigate("/home");
    }
  }, [props.isAuthenticated]);
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showError, setShowError] = useState(false);

  const onLogin = async () => {
    if (userName != "" && password != "") {
      showLoading(true);
      var details: LoginDetails = {
        username: userName,
        password: password,
      };
      await props.userLogin(details, callback);
    } else {
      setShowError(true);
    }
  };

  const callback = (status: any) => {
    if (status) {
      alert("Success");
    } else {
      showLoading(false);
      alert("Login Failed");
    }
  };

  return (
    <>
      <main>
        <section className="loginwrap align-items-center">
          <div className="container">
            <div className="row">
              <div className="col-md-4"></div>
              <div className="col-md-4">
                <div className="fxt-content admission_desc ">
                  <div className="fxt-form">
                  
                  <h1>Smart Tech</h1>
                    <h3>Login</h3>
                    
                    {/* <form> */}
                    <div className="main-form row">
                      <div className="controls formgroup col-md-12">
                        <input
                          type="text"
                          className="floatLabel"
                          value={userName}
                          onChange={(e) => setUserName(e.target.value)}
                        />
                        <label
                          className={`${userName.length > 0 ? "active" : ""}`}
                        >
                          Username<i>*</i>
                        </label>
                      </div>
                      <div className="controls formgroup col-md-12 showpassword">
                        <input
                          id="showpass"
                          type={`${showPassword ? "text" : "password"}`}
                          className="floatLabel"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                        <label
                          className={`${password.length > 0 ? "active" : ""}`}
                        >
                          Password
                        </label>
                        <div>
                          {
                            <input
                              type="checkbox"
                              onClick={() => setShowPassword(!showPassword)}
                            />
                          }
                          <span>
                            <i className="fas fa-eye"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="row align-items-center">
                      <div className="col-md-6">
                        
                      </div>
                      <div className="col-md-6">
                        <button className="btn" onClick={() => onLogin()}>
                          {/* <ButtonSpinner isLoading={isLoading} /> */}
                          Log In
                        </button>
                      </div>
                    </div>
                    {userName == "" ||
                      (password == "" && showError && (
                        <div className="errors">
                          * Username and Password are mandatory
                        </div>
                      ))}
                    {/* </form> */}
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <input type="text" placeholder="UserName" value={userName} onChange={e=>setUserName(e.target.value)} />
      <input type="text" placeholder="Password" value={password} onChange={e=>setPassword(e.target.value)}/>
      <button onClick={()=>onLogin()}>Login</button> */}
      </main>
    </>
  );
};
const mapDispatchToProps = {
  userLogin: (loginDetails: any, callback: any) =>
    login(loginDetails, callback),
  
};

function mapStateToProps(state: any) {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    role: state.auth.role,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
