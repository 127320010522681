import React from 'react';

interface FloatingLabelTextInputProp {
    label: string;
    onChange: any;
    isMandatory:boolean;
    value:any;
    name:string;
    isNumber:boolean;
    disabled?:boolean;
}

const FloatingLabelTextInput = (props: FloatingLabelTextInputProp) => {
    const { onChange } = props;
    const{label,isMandatory,value,name,isNumber,disabled}=props;
    return (
        <>
            <input
                className="floatLabel"
                name={name}
                type={isNumber?"number":"text"}
                value={value}
                onChange={e => onChange(e)}
                disabled={disabled}
                
            />
            <label className={`${value.length>0 ? 'active' : ''}`}>
                {label}
                {isMandatory && <i>*</i>}
            </label>
        </>
    )
}

export default FloatingLabelTextInput;