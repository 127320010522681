import { createSlice } from "@reduxjs/toolkit";
import { CustomerModal, EmployeeModel, Service, ServiceModal } from "../models";

const customerState: CustomerModal = {
    serviceHistory:undefined,
    service:undefined,
    cashBox:undefined,
    pending:undefined,
    serviceDetails:undefined
};
const customerSlice = createSlice({
    name: "employee",
    initialState: customerState,
    reducers: {
        onFetchServiceHistory: (state, action: { payload: any; }) => {
            state.serviceHistory=action.payload;
        },
        onFetchPendingServiceHistory: (state, action: { payload: any; }) => {
            state.pending=action.payload;
        },
        onFetchCustomerServiceByIdSuccess: (state, action: { payload: any; }) => {
            state.service=action.payload;
        },
        onFetchCashBoxDetails:(state,action:{payload:any;})=>{
            state.cashBox=action.payload;
        },
        onFetchServiceDetails:(state,action:{payload:any})=>{
            state.serviceDetails=action.payload;
        }
    },
});

export const { 
    onFetchServiceHistory,
    onFetchCustomerServiceByIdSuccess,
    onFetchCashBoxDetails,
    onFetchPendingServiceHistory,
    onFetchServiceDetails
    
 } = customerSlice.actions;

const customerReducer = customerSlice.reducer;

export type employeeState = ReturnType<typeof customerReducer>

export default customerReducer;