import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App.tsx';
import { HashRouter } from 'react-router-dom';
import { Provider } from "react-redux";
import store from "./store/index.ts";

import reportWebVitals from './reportWebVitals';
import { PersistGate } from 'redux-persist/integration/react'
import { persistStore } from 'redux-persist'
let persistor = persistStore(store);


ReactDOM.render(
    <Provider store={store}>
    <HashRouter>
    <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </HashRouter>
    </Provider>,document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
