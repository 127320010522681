import apiService, { ApiService } from "../api-service.ts";


class CustomerService {
    /**
     * api service helper.
     */
    private _apiService: ApiService;

    /**
     * instaniates AuditService;
     */
    constructor() {
        this._apiService = apiService;
    }

    public GetServiceHistory(data: any) {
        return this._apiService.get(`customerservice/getAll?page=${data.page}&keyword=${data.searchText}`);
    }

    public GetPendingServiceHistory(data: any) {
        return this._apiService.get(`customerservice/getPending?page=${data.page}&keyword=${data.searchText}`);
    }

    public SaveCustomerService(data:any) {
        return this._apiService.postFormData(`customerservice/create`,data);
    }
    public UpdateCustomerService(data:any) {
        return this._apiService.postFormData(`customerservice/update`,data);
    }

    public GetCashbox(center:number) {
        return this._apiService.get(`customerservice/getCashbox?center=${center}`);
    }

    public ResetCashbox(data:any) {
        return this._apiService.post(`customerservice/resetCashbox`,data);
    }

    public GetCustomFields(id: any) {
        return this._apiService.get(`customerservice/getCustomFields?id=${id}`);
    }

    public GetCustomer(mobile: any) {
        return this._apiService.get(`customerservice/getCustomer?mobile=${mobile}`);
    }

    public GetServiceById(id: number) {
        return this._apiService.get(`customerservice/getById?id=${id}`);
    }

    public GetrServiceCharge(id: number) {
        return this._apiService.get(`customerservice/getServiceCharge?id=${id}`);
    }
    
}

export default new CustomerService();