import React, { useEffect, useState } from "react";
import { useRef } from "react";
import FloatingLabelTextInput from "../../components/floatingtextinput.tsx";
import Joi, { ValidationErrorItem } from "joi";
import { connect } from "react-redux";
import { fetchServiceById, saveService, updateService } from "../../actions/serviceActionCreator.ts";
import { json, useLocation, useNavigate } from "react-router-dom";
import { LoginDetails, Service } from "../../models/index.ts";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { saveEmployee } from "../../actions/employeeActionCreator.ts";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { FileUploader } from "react-drag-drop-files";
import { resetPassword } from "../../actions/auth/authActionCreator.ts";

const fileTypes = ["JPG", "PNG"];

interface PasswordResetProps {
    saveEmployee: (data: any, callback: any) => void;
    isAuthenticated: boolean;
    role: string;
    resetPassword: (data: any) => void;
    userName: string;
}
const PasswordReset = (props: PasswordResetProps) => {
    let navigate = useNavigate();
    const { state } = useLocation();

    const [errors, setErrors] = useState<any>(undefined);
    const [heading, setHeading] = useState("Create Employee");
    const [buttonText, setButtonText] = useState("Save");
    const [joined, setJoined] = useState(new Date());
    var intialDetails = {
        password: "",
        confirmPassword: "",
    }
    const [passwordDetails, setPasswordDetails] = useState(
        intialDetails
    );
    const [file, setFile] = useState(null);
    const [chechUpdate, setCheck] = useState(false);
    const handleChange = (file) => {
        setFile(file);
    };

    useEffect(() => {
        if (!props.isAuthenticated) {
            navigate("/login");
        }
    }, []);
    const onChange = (
        event:
            | React.ChangeEvent<HTMLInputElement>
            | React.ChangeEvent<HTMLSelectElement>
    ) => {
        const _guardianDetails = {
            ...JSON.parse(JSON.stringify(passwordDetails)),
            [event.target.name]: event.target.value,
        };
        setPasswordDetails(_guardianDetails);
        validateFields(_guardianDetails);
    };


    const schema = Joi.object().keys({
        password: Joi.string().min(6).required().label("Enter Valid Password"),
        confirmPassword: Joi.string()
            .valid(Joi.ref('password')).required().label("Password mismatch").messages({
                'any.only': 'Passwords must match',
            }),
    });
    const validateFields = (requestDetails: any) => {
        setErrors(undefined);
        const result = schema.validate(requestDetails, {
            abortEarly: false,
            allowUnknown: true,
        });
        result.error &&
            result.error.details &&
            setErrorFromJoi(result.error.details);
    };
    const setErrorFromJoi = (validationErrors: Array<ValidationErrorItem>) => {
        let _erroObject = {};
        validationErrors.map((errorItem) => {
            if (errorItem.context && errorItem.context.key) {
                _erroObject = {
                    ..._erroObject,
                    [errorItem.context.key]: errorItem.context.label,
                };
            }
        });
        setErrors(_erroObject);
    };
    const onSubmit = async () => {
        const result = schema.validate(passwordDetails, {
            abortEarly: false,
            allowUnknown: false,
        });
        if (result.error == undefined) {
            callback(true);
            var details: LoginDetails = {
                username: props.userName,
                password: passwordDetails.password,
            };
            await props.resetPassword(details);
        } else {
            result.error &&
                result.error.details &&
                setErrorFromJoi(result.error.details);
        }
    };

    const callback = (status) => {
        toast("Updated Successfully");
        navigate("/home");
    }

    return (
        <>
            <div className="studdash mt-4">
                <div className="container">
                    <div className="card">
                        <h2>{"Reset Password"}</h2>

                        <div className="main-form row">
                            <div className="controls formgroup col-md-6">
                                {/* <FloatingLabelTextInput
                                    isMandatory
                                    name={"password"}
                                    label={"Password"}
                                    onChange={onChange}
                                    value={passwordDetails.password}
                                    isNumber={false}
                                /> */}
                                <input
                                    className="floatLabel"
                                    name={"password"}
                                    type={"password"}
                                    value={passwordDetails.password}
                                    onChange={e => onChange(e)}

                                />
                                <label className={`${passwordDetails.password.length > 0 ? 'active' : ''}`}>
                                    {"Password"}
                                    <i>*</i>
                                </label>
                                {errors && errors.password && (
                                    <div className="errors">{errors.password}</div>
                                )}
                            </div>
                            <div className="controls formgroup col-md-6">
                                {/* <FloatingLabelTextInput
                                    isMandatory
                                    name={"confirmPassword"}
                                    label={"Confirm Password"}
                                    onChange={onChange}
                                    value={passwordDetails.confirmPassword}
                                    isNumber={false}
                                /> */}
                                <input
                                    className="floatLabel"
                                    name={"confirmPassword"}
                                    type={"password"}
                                    value={passwordDetails.confirmPassword}
                                    onChange={e => onChange(e)}

                                />
                                <label className={`${passwordDetails.confirmPassword.length > 0 ? 'active' : ''}`}>
                                    {"Confirm Password"}
                                    <i>*</i>
                                </label>
                                {errors && errors.confirmPassword && (
                                    <div className="errors">{errors.confirmPassword}</div>
                                )}
                            </div>

                            <div className="btnwrap">
                                <button
                                    className="btn"
                                    onClick={() => navigate("/home")}
                                >
                                    {"Cancel"}
                                </button>
                                <button
                                    className="btn"
                                    onClick={() => onSubmit()}
                                >
                                    {buttonText}
                                </button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const mapDispatchToProps = {
    saveEmployee: (data: any, callback: any) => saveEmployee(data, callback),
    resetPassword: (data: any) => resetPassword(data)
}

function mapStateToProps(state: any) {
    return {
        isAuthenticated: state.auth.isAuthenticated,
        service: state.service.service,
        role: state.auth.role,
        userName: state.auth.userName,
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(PasswordReset);