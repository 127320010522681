import logo from './logo.svg';
import './App.css';
import Router from "./router/router.tsx";
import React from 'react';
import Header from './components/header.tsx';
import { useLocation } from 'react-router-dom';
import Login from './pages/login/login.tsx';
import { useSelector } from 'react-redux';


function App() {
  const location = useLocation();

  let isAuthenticated = useSelector(
    (state: any) => state.auth.isAuthenticated
  );
  return (
    <>
      <div className="en">
          <> <Header />
            <main className={isAuthenticated ? "main-content" : "main-content p-0"}>
              <Router />
            </main>

          </>
        
      </div>
    </>
  );
}

export default App;
