import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import { logout } from "../actions/auth/authActionCreator.ts";
import { BASE_URL } from "../constants/url.constants.ts";

interface HeaderProps {
    isAuthenticated?: boolean;
    role?: string;
    userName?: string;
    logout:(callback:any)=>void;
    profile:string;
}
const Header = (props: HeaderProps) => {
    const location = useLocation();
    let navigate = useNavigate();
    const toggleDropdown=()=>{
        var dropdown = document.getElementById("dropdown");
        if(dropdown!=null){
        dropdown.style.display = dropdown.style.display === "none" ? "block" : "none";
        }
    }
    const callback=()=>{
        // navigate("/login");
    }

    useEffect(()=>{
        if (!props.isAuthenticated) {
              navigate("/login");
          }
    },[props.isAuthenticated])
    return (
        <>
            {props.isAuthenticated &&
                <header className="stud-header">
                    <div>
                        Smart Tech
                    </div>

                    <div className="right-header">

                        {/* <div className="profile">
                            <div className="name">
                                <h6>{props.userName}</h6>
                                <span>{props.role}</span>
                            </div>
                            <div className="img">
                                <img src="assets/images/avatar.jpg" alt="" />
                            </div>
                            <div id="dropdown" className="dropdown-content">
                                <a href="#">Logout</a>
                                <a href="#">Reset Password</a>
                            </div>
                        </div> */}
                        <div className="profile">
                        <div className="name">
                                <h6>{props.userName}</h6>
                                <span>{props.role}</span>
                            </div>
        <img style={{marginLeft:10}} src={props?.profile?BASE_URL + props.profile:"assets/images/avatar.jpg"} alt="Profile Image" className="profile-img" onClick={()=>toggleDropdown()} />
        <div id="dropdown" className="dropdown-content">
            <a onClick={()=>{props.logout(callback)}}>Logout</a>
            <a onClick={()=>{navigate("/reset-password");toggleDropdown()}}>Reset Password</a>
        </div>
    </div>


                        {/* {token ? (
              <button
                title="Logout"
                className="custom-logout"
                onClick={() => setWarningAlertLogout(true)}

                // onClick={() => logout()}
              ></button>
            ) : (
              ""
            )} */}
                    </div>
                    <nav className="sidenav-stud sidebar-nav">
                        <ul>
                            <li>
                                <a
                                    href="#"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        navigate("/");
                                    }}
                                >
                                    {"Home"}
                                </a>
                            </li>

                            
                            {props.role?.includes("Admin")&&(<>
                                <li>
                                <a
                                    href="#"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        navigate("/service-index");
                                    }}
                                >
                                    {"Service Types"}
                                </a>
                            </li>
                                <li>
                                <a
                                    href="#"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        navigate("/employee-index");
                                    }}
                                >
                                    {"Employee"}
                                </a>
                            </li>
                            </>)}
                            
                            <li>
                                <a
                                    href="#"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        navigate("/customer-service-index");
                                    }}
                                >
                                    {"Customer Service"}
                                </a>
                            </li>


                        </ul>
                    </nav>

                </header>

            }
            <ToastContainer />
        </>
    )
}
const mapDispatchToProps={
    logout:(callback:any)=>logout(callback)
}
function mapStateToProps(state: any) {
    return {
        isAuthenticated: state.auth.isAuthenticated,
        token: state.auth?.token,
        role: state.auth.role,
        userName: state.auth.firstName,
        profile:state.auth.profile
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);