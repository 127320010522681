import { createSlice } from "@reduxjs/toolkit";
import { Service, ServiceModal } from "../models";

const serviceState: ServiceModal = {
    services:undefined,
    customValues:undefined,
    serviceList:undefined,
    customFields:[]
};
const serviceSlice = createSlice({
    name: "service",
    initialState: serviceState,
    reducers: {
        onFetchServices: (state, action: { payload: any; }) => {
            state.services=action.payload;
        },
        onFetchServiceByIdSuccess: (state, action: { payload: any; }) => {
            state.service=action.payload;
        },
        onFetchCustomValues:(state, action: { payload: any; }) => {
            state.customValues=action.payload;
        },
        onFetchServiceList:(state, action: { payload: any; }) => {
            state.serviceList=action.payload;
        },
        onFetchCustomFields:(state, action: { payload: any; }) => {
            state.customFields=action.payload;
        },
    },
});

export const { 
    onFetchServices,
    onFetchServiceByIdSuccess,
    onFetchCustomValues,
    onFetchServiceList,
    onFetchCustomFields
 } = serviceSlice.actions;

const serviceReducer = serviceSlice.reducer;

export type serviceState = ReturnType<typeof serviceReducer>

export default serviceReducer;