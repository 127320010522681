import smartService from "../api/services/smartService.ts";
import { onLogout } from "../slices/auth.slice.ts";
import { onFetchCustomValues, onFetchServiceByIdSuccess, onFetchServiceList, onFetchServices } from "../slices/service.slice.ts";

export function fetchServices(page:number,searchText:string) {
    return async function (dispatch: any,getState: any) {
        try {
            let data = await smartService.GetServices({page,searchText});
            if (data.status === 200) {
                console.log(data.data.data);
                dispatch(onFetchServices(data.data));
            }
        } catch(error) {
            if(error.response?.status==401){
                dispatch(onLogout());
            }
            console.log("error");
        }
    };
}

export function saveService(details:any,callback:any) {
    return async function (dispatch: any,getState: any) {
        try {
            let data = await smartService.SaveService(details);
            if (data.status === 200) {
                console.log(data.data.data);
                callback();
            }
        } catch(error) {
            if(error.response?.status==401){
                dispatch(onLogout());
            }
            console.log("error");
        }
    };
}

export function fetchServiceById(id:number) {
    return async function (dispatch: any,getState: any) {
        try {
            let data = await smartService.GetServiceById(id);
            if (data.status === 200) {
                console.log(data.data.data);
                dispatch(onFetchServiceByIdSuccess(data.data));
            }
        } catch(error) {
            if(error.response?.status==401){
                dispatch(onLogout());
            }
            console.log("error");
        }
    };
}

export function updateService(details:any,callback:any) {
    return async function (dispatch: any,getState: any) {
        try {
            let data = await smartService.UpdateService(details);
            if (data.status === 200) {
                console.log(data.data.data);
                callback();
            }
        } catch(error) {
            if(error.response?.status==401){
                dispatch(onLogout());
            }
            console.log("error");
        }
    };
}

export function saveCustomValues(details:any,callback:any) {
    return async function (dispatch: any,getState: any) {
        try {
            let data = await smartService.SaveCustomValues(details);
            if (data.status === 200) {
                console.log(data.data.data);
                callback();
            }
        } catch(error) {
            if(error.response?.status==401){
                dispatch(onLogout());
            }
            console.log("error");
        }
    };
}

export function getCustomValues(id:number) {
    return async function (dispatch: any,getState: any) {
        try {
            let data = await smartService.GetCustomValues(id);
            if (data.status === 200) {
                console.log(data.data.data);
                dispatch(onFetchCustomValues(data.data));
            }
        } catch(error) {
            if(error.response?.status==401){
                dispatch(onLogout());
            }
            console.log("error");
        }
    };
}

export function getServiceList() {
    return async function (dispatch: any,getState: any) {
        try {
            let data = await smartService.GetServiceList();
            if (data.status === 200) {
                console.log(data.data.data);
                dispatch(onFetchServiceList(data.data));
            }
        } catch(error) {
            if(error.response?.status==401){
                dispatch(onLogout());
            }
            console.log("error");
        }
    };
}