import { combineReducers } from "redux";
import authReducer from "./auth.slice.ts";
import serviceReducer from "./service.slice.ts";
import employeeReducer from "./employee.slice.ts";
import customerReducer from "./customer.slice.ts";
const rootReducer = combineReducers({
    auth: authReducer,
    service:serviceReducer,
    employee:employeeReducer,
    customer:customerReducer
  });
  
  export default rootReducer;