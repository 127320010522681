import apiService, { ApiService } from "../api-service.ts";


class EmployeeService {
    /**
     * api service helper.
     */
    private _apiService: ApiService;

    /**
     * instaniates AuditService;
     */
    constructor() {
        this._apiService = apiService;
    }

    public GetEmployees(data: any) {
        return this._apiService.get(`employee/getAll?page=${data.page}`);
    }

    public SaveEmployee(data:any) {
        return this._apiService.postFormData(`employee/create`,data);
    }

    public GetemployeeById(id: number) {
        return this._apiService.get(`employee/getById?id=${id}`);
    }

    public UpdateEmployee(data:any) {
        return this._apiService.postFormData(`employee/update`,data);
    }
}

export default new EmployeeService();