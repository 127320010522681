import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import FloatingLabelTextInput from "../../components/floatingtextinput.tsx"
import { toast } from "react-toastify";
import { fetchEmployeeById, updateEmployee } from "../../actions/employeeActionCreator.ts";
import { FileUploader } from "react-drag-drop-files";
import { useLocation, useNavigate } from "react-router-dom";
import Joi, { ValidationErrorItem } from "joi";
import DatePicker from "react-datepicker";
import { Employee } from "../../models";
import "react-datepicker/dist/react-datepicker.css";
import Avatar from 'react-avatar';
import { BASE_URL } from "../../constants/url.constants.ts";
import moment from "moment";
const fileTypes = ["JPG", "PNG"];




interface EmployeeEditProps {
    isAuthenticated:boolean;
    role:string;
    updateEmployee: (data: any, callback: any) => void;
    fetchEmployeeById: (id: number) => void;
    employee: Employee;
}
const EmployeeEdit = (props: EmployeeEditProps) => {
    let navigate = useNavigate();
    const { state } = useLocation();

    const [errors, setErrors] = useState<any>(undefined);
    const [heading, setHeading] = useState("Create Employee");
    const [buttonText, setButtonText] = useState("Save");
    const [joined, setJoined] = useState(new Date());
    const [profile,setProfile]=useState("");
    var intialDetails = {
        name: "",
        email: "",
        phone: "",
        joined: "",
        userId: "",
        center:0
    }
    const [employeeDetails, setEmployeeDetails] = useState(
        intialDetails
    );
    const [file, setFile] = useState(null);
    useEffect(()=>{
        if(!props.isAuthenticated){
            navigate("/login");
        }
        else if(!props.role?.includes("Admin")){
            navigate("/");
        }
    },[]);

    const handleChange = (file) => {
        setFile(file);
    };

    const onChange = (
        event:
            | React.ChangeEvent<HTMLInputElement>
            | React.ChangeEvent<HTMLSelectElement>
    ) => {
        const _guardianDetails = {
            ...JSON.parse(JSON.stringify(employeeDetails)),
            [event.target.name]: event.target.value,
        };
        setEmployeeDetails(_guardianDetails);
        validateFields(_guardianDetails);
    };


    const schema = Joi.object().keys({
        name: Joi.string().required().label("Enter Name"),
        email: Joi.string()
            .required()
            .email({ tlds: { allow: false } })
            .label("Enter Valid Email"),
        phone: Joi.string()
            .required()
            .label("Enter Valid Phone number"),
            joined: Joi.string()
            .optional()
            .label("Enter Valid Phone number"),
            prfile: Joi.string()
            .optional()
            .label("Enter Valid Phone number"),
            userId: Joi.string()
            .optional()
            .label("Enter Valid Phone number"),
            center: Joi.number()
            .required()
            .label("Enter Valid center"),
    });
    const validateFields = (requestDetails: any) => {
        setErrors(undefined);
        const result = schema.validate(requestDetails, {
            abortEarly: false,
            allowUnknown: true,
        });
        result.error &&
            result.error.details &&
            setErrorFromJoi(result.error.details);
    };
    const setErrorFromJoi = (validationErrors: Array<ValidationErrorItem>) => {
        let _erroObject = {};
        validationErrors.map((errorItem) => {
            if (errorItem.context && errorItem.context.key) {
                _erroObject = {
                    ..._erroObject,
                    [errorItem.context.key]: errorItem.context.label,
                };
            }
        });
        setErrors(_erroObject);
    };
    const onSubmit = async () => {
        const result = schema.validate(employeeDetails, {
            abortEarly: false,
            allowUnknown: false,
        });
        if (result.error == undefined) {
                const formData = new FormData();
                formData.append("Id",state?.id);
                formData.append("Name", employeeDetails.name);
                formData.append("Email", employeeDetails.email);
                formData.append("Phone", employeeDetails.phone);
                formData.append("Center",JSON.parse(JSON.stringify(employeeDetails.center)));
                formData.append("Joined", JSON.parse(JSON.stringify(joined)));
                if (file != null) {
                    formData.append("Photo", file);
                }

                await props.updateEmployee(
                    formData,
                    callback
                );
            
        } else {
            result.error &&
                result.error.details &&
                setErrorFromJoi(result.error.details);
        }
    };

    const callback = (status) => {
        toast("Updated Successfully");
        navigate("/employee-index");
    }

    useEffect(() => {
        if (state?.id != undefined) {
            props.fetchEmployeeById(state.id);
        }
    }, [])

    useEffect(() => {
        if (state?.id != undefined && props?.employee != undefined) {
            var editDetails = {
                name: props?.employee.name,
                email: props?.employee?.email,
                phone: props?.employee?.phone,
                joined: props?.employee?.joined,
                userId: props?.employee?.userId,
                center:props?.employee?.center
            };
            setProfile(props?.employee?.profile);
            setEmployeeDetails(editDetails);
        }
    }, [props?.employee])
    return (
        <>
            <div className="studdash mt-4">
                <div className="container">
                    <div className="card">
                        <h2>Update Employee</h2>

                        <div className="main-form row">
                        <div className="controls formgroup col-md-12">
                        <Avatar size="100" round src={BASE_URL+profile+"?"+moment().format()} />
                            </div>
                        
                            <div className="controls formgroup col-md-6">
                                <FloatingLabelTextInput
                                    isMandatory
                                    name={"name"}
                                    label={"Name"}
                                    onChange={onChange}
                                    value={employeeDetails.name}
                                    isNumber={false}
                                />
                                {errors && errors.name && (
                                    <div className="errors">{errors.name}</div>
                                )}
                            </div>
                            <div className="controls formgroup col-md-6">
                                <FloatingLabelTextInput
                                    isMandatory
                                    name={"email"}
                                    label={"Email"}
                                    onChange={onChange}
                                    value={employeeDetails.email}
                                    isNumber={false}
                                />
                                {errors && errors.email && (
                                    <div className="errors">{errors.email}</div>
                                )}
                            </div>
                            <div className="controls formgroup col-md-6">
                                <FloatingLabelTextInput
                                    isMandatory
                                    name={"phone"}
                                    label={"Phone"}
                                    onChange={onChange}
                                    value={employeeDetails.phone}
                                    isNumber={true}
                                />
                                {errors && errors.phone && (
                                    <div className="errors">{errors.phone}</div>
                                )}
                            </div>
                            <div className="controls formgroup col-md-6">
                                    <select name="center" className="form-control"
                                        onChange={onChange}
                                        value={employeeDetails.center}>
                                        <option value={0}>Thodannur</option>
                                        <option value={1}>Cherandathur</option>
                                        <option value={2}>Thurasheri Kadav</option>
                                    </select>
                                    <label className={'active'}>
                                        Center
                                        <i>*</i>
                                    </label>
                                </div>
                            <div className="controls formgroup col-md-6">
                                <DatePicker selected={joined} onChange={(date) => setJoined(date)} />
                                <label className='active'>
                                    Joined Date
                                </label>
                            </div>
                            <FileUploader handleChange={handleChange} name="file" types={fileTypes} />
                            <div className="btnwrap">
                            <button
                                    className="btn"
                                    onClick={() => navigate("/employee-index")}
                                >
                                    {"Cancel"}
                                </button>
                                <button
                                        className="btn"
                                        onClick={() => onSubmit()}
                                    >
                                        {"Update"}
                                    </button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const mapDispatchToProps = {
    updateEmployee: (data: any, callback: any) => updateEmployee(data, callback),
    fetchEmployeeById: (id: number) => fetchEmployeeById(id)
}

function mapStateToProps(state: any) {
    return {
        isAuthenticated: state.auth.isAuthenticated,
        employee: state.employee.employee,
        role:state.auth.role
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(EmployeeEdit)