import React from "react";
import { Routes, Route } from "react-router-dom";
import HomePage from "../pages/homePage/homePage.tsx";
import Login from "../pages/login/login.tsx";
import ServiceCreate from "../pages/service/serviceCreate.tsx";
import ServiceIndex from "../pages/service/serviceIndex.tsx";
import EmployeeIndex from "../pages/employee/employeeIndex.tsx";
import EmployeeCreate from "../pages/employee/employeeCreate.tsx";
import EmployeeEdit from "../pages/employee/employeeEdit.tsx";
import CustomerServiceCreate from "../pages/customerService/customerServiceCreate.tsx";
import customerServiceIndex from "../pages/customerService/customerServiceIndex.tsx";
import customerServiceEdit from "../pages/customerService/customerServiceEdit.tsx";
import ResetPassword from '../pages/reset/passwordReset.tsx';
const Router = () => {
    return (
        <>
            <Routes>
                <Route path="/*" Component={HomePage}></Route>
                <Route path="/login" Component={Login}></Route>
                <Route path="/service-create" Component={ServiceCreate}></Route>
                <Route path="/service-index" Component={ServiceIndex}></Route>
                <Route path="/employee-index" Component={EmployeeIndex}></Route>
                <Route path="/employee-create" Component={EmployeeCreate}></Route>
                <Route path="/employee-edit" Component={EmployeeEdit}></Route>
                <Route path="/customer-service-create" Component={CustomerServiceCreate}></Route>
                <Route path="/customer-service-index" Component={customerServiceIndex}></Route>
                <Route path="/customer-service-edit" Component={customerServiceEdit}></Route>
                <Route path="/reset-password" Component={ResetPassword}></Route>
            </Routes>
        </>
    )
};


export default Router;