import React, { useEffect, useState } from "react";
import Header from "../../components/header";
import { connect } from "react-redux";
import { logout } from "../../actions/auth/authActionCreator.ts";
import { useNavigate } from "react-router-dom";
import { fetchCashBoxDetails, fetchPendingServiceHistory, resetCashBox } from "../../actions/customerActionCreator.ts";
import { CashBox, CustomerService, CustomerServiceList } from "../../models/index.ts";
import ReactPaginate from "react-paginate";
import Avatar from "react-avatar";
import { BASE_URL } from "../../constants/url.constants.ts";
import moment from "moment";


interface HomeProps {
    isAuthenticated: boolean;
    logout: (callback: any) => void;
    fetchCashBoxDetails: (center: number) => void;
    cashBox: CashBox;
    roles: string;
    center: string;
    fetchPendingServiceHistory: (page: number, searchText: string) => void;
    pending?: CustomerServiceList;
    resetCashBox:(data:any,callback:any)=>void;
}
const HomePage = (props: HomeProps) => {
    const navigate = useNavigate();
    const [cash, SetCash] = useState(0);
    const [account, SetAccount] = useState(0);
    const [payworld, SetPayworld] = useState(0);
    const [center, setCenter] = useState(0);
    const [count, setCount] = useState(10);
    const [page, setPage] = useState(1);
    const [searchText, setSearchText] = useState("");
    const [editable,setEditable]=useState(false);
    const callback = () => {
        //navigate("/login");
    }
    useEffect(() => {
        if (props.center == null || props.center == undefined || props.center == "100") {
            setCenter(0);
        }
        else {
            setCenter(parseInt(props.center));
        }
    }, [props.center]);
    const handlePageClick = (data: any) => {
        setPage(++data.selected);
    };

    useEffect(() => {
        props.fetchPendingServiceHistory(page, searchText);
    }, [page]);

    useEffect(() => {
        //if(searchText.length>2){
        props.fetchPendingServiceHistory(page, searchText);
        //}
    }, [searchText]);

    useEffect(() => {
        if (!props.isAuthenticated) {
            navigate("/login");
        }
        //props.fetchCashBoxDetails(center);
        
    }, []);
    useEffect(() => {
        props.fetchCashBoxDetails(center);
    }, [center]);
    
const updateCashBox=()=>{
    let request={
        "Cash":cash,
        "Account":account,
        "Payworld":payworld,
        "Center":center
    };
    props.resetCashBox(request,callback);
}

    useEffect(() => {
        if (props.cashBox != undefined && props.cashBox != null) {
            SetCash(props.cashBox.cash);
            SetAccount(props.cashBox.account);
            SetPayworld(props.cashBox.payworld);
        }
        else {
            SetCash(0);
            SetAccount(0);
            SetPayworld(0);
        }
    }, [props.cashBox])
    return (
        <>{props?.center == "100" && (<>
            <div className="controls formgroup col-md-6">
                <select name="center" className="form-control"
                    onChange={e => setCenter(parseInt(e.target.value))}
                    value={center}>
                    <option value={0}>Thodannur</option>
                    <option value={1}>Cherandathur</option>
                    <option value={2}>Thurasheri Kadav</option>
                </select>
                <label className={'active'}>
                    Choose Center

                </label>
            </div></>)
        }
            <div className="card-container" style={{marginLeft:0}}>
                <div className="card1">
                    <h2>Cash Box</h2>
                    {editable?<><input type="number" value={cash} 
                    onChange={(e)=>SetCash(parseInt(e.target.value))}
                    onBlur={()=>updateCashBox()}/></>:<><p>{cash}</p></>}
                </div>
                <div className="card1">
                    <h2>PayWorld</h2>
                    {editable?<><input type="number" value={payworld} 
                    onChange={(e)=>SetPayworld(parseInt(e.target.value))}
                    onBlur={()=>updateCashBox()}/></>:<><p>{payworld}</p></>}
                </div>
                <div className="card1">
                    <h2>Account</h2>
                    {editable?<><input type="number" value={account} 
                    onChange={(e)=>SetAccount(parseInt(e.target.value))}
                    onBlur={()=>updateCashBox()}/></>:<><p>{account}</p></>}
                    {/* {props.roles?.includes("Admin") && (<><p>{account}</p></>)}
            {!props.roles?.includes("Admin") && (<><p>**</p></>)} */}
                </div>
                {props.roles?.includes("Admin") && (<button type="button" className="btn btn-light" onClick={()=>setEditable(!editable)} style={{position:"absolute",top:'165px',right:'60px'}}><i className="fa fa-edit"></i></button>)}
            </div>

            <div className="card">
                <div className="content-filter">
                    <h2>Pending Services</h2>
                    <div className="d-flex">
                        <input type="text"
                            placeholder="Search"
                        onChange={e=>setSearchText(e.target.value)}
                        value={searchText} 
                        />
                        <a
                            className="btn"
                            href="javascript:void(0)"
                        onClick={() => navigate("/customer-service-create")}
                        >
                            Add New
                        </a>
                    </div>
                </div>
                <div className="table-responsive">
                    <table className="table align-items-center mb-0">
                        <thead>
                            <tr>
                                <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Created By</th>
                                <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Service</th>
                                <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Customer</th>
                                <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Pending On</th>
                                <th className="text-secondary opacity-7"></th>
                            </tr>
                        </thead>
                            {props.pending?.data ? (
                                props.pending?.data.length > 0 ? (
                                    <tbody id="filterinfo">
                                        {props.pending
                                            ? props.pending?.data.map(
                                                (c: CustomerService) => {
                                                    return (
                                                        <>
                                                            <tr>
                                                                <td>
                                                                    <div className="d-flex px-2 py-1">
                                                                        <div>
                                                                            <Avatar size="45" round src={BASE_URL + c.employeeProfile} />

                                                                        </div>
                                                                        <div className="d-flex flex-column justify-content-center" style={{ marginLeft: 5 }}>
                                                                            <h6 className="mb-0 text-xs">{c.employeeName}</h6>

                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td className="align-middle">
                                                                    <span className="text-xs font-weight-bold mb-0">{c.service}</span>

                                                                </td>
                                                                <td className="text-center">
                                                                    <p className="text-xs font-weight-bold mb-0">{c.customerName}  </p>
                                                                    <p className="text-xs text-secondary mb-0">{c.customerPhone}</p>
                                                                </td>

                                                                <td className="align-middle text-center">
                                                                    <span className="text-secondary text-xs font-weight-normal">{moment(c.createdOn).add(5, 'hour').add(30, 'minute').format("DD MMM YYYY hh:mm a")}</span>
                                                                </td>
                                                                <td className="align-middle">
                                                                    <a href="javascript:;" className="text-secondary font-weight-normal text-xs" data-toggle="tooltip" data-original-title="Edit user"
                                                                        onClick={() => {
                                                                            navigate(
                                                                                "/customer-service-edit", { state: { id: c.id } }
                                                                            );
                                                                        }}>
                                                                        View
                                                                    </a>
                                                                </td>
                                                            </tr>
                                                        </>
                                                    );
                                                }
                                            )
                                            : null}
                                    </tbody>
                                ) : (
                                    <p>No Data Found</p>
                                )
                            ) : null}
                    </table>
                </div>
                {props.pending
                    ? props.pending?.totalRecords
                        ? props.pending?.totalRecords > count && (
                            <ReactPaginate
                                previousLabel={"←"}
                                nextLabel={"→"}
                                breakLabel={"..."}
                                breakClassName={"break-me"}
                                pageCount={
                                    props.pending
                                        ? Math.ceil(props.pending.totalRecords / 10)
                                        : 1
                                }
                                marginPagesDisplayed={1}
                                pageRangeDisplayed={2}
                                onPageChange={handlePageClick}
                                containerClassName={"pagination"}
                                activeClassName={"active"}
                            />
                        )
                        : null
                    : null}
            </div>
        </>
    )
}

const mapDispatchToProps = {
    logout: (callback: any) => logout(callback),
    fetchCashBoxDetails: (center: number) => fetchCashBoxDetails(center),
    fetchPendingServiceHistory: (page: number, searchText: string) => fetchPendingServiceHistory(page, searchText),
    resetCashBox:(data:any,callback:any)=>resetCashBox(data,callback)
}

function mapStateToProps(state: any) {
    return {
        isAuthenticated: state.auth.isAuthenticated,
        cashBox: state.customer.cashBox,
        roles: state.auth.role,
        center: state.auth.center,
        pending: state.customer.pending
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);