import axios, { AxiosInstance } from "axios";
import {
  AppConfig,
  AUTHENTICATION_URL,
  BASE_URL,
} from "../constants/url.constants.ts";
import { useDispatch } from "react-redux";
import { onLogout } from "../slices/auth.slice.ts";

export class ApiService{
  private axiosInstance: AxiosInstance;
  private baseURL = BASE_URL;
  private IdentityURL = AUTHENTICATION_URL;
  //private dispatch = useDispatch();
  constructor() {
    this.axiosInstance = axios.create();
    this.setupRequestInterceptors();
    this.setupResponseInterceptors();
  }

  private setupRequestInterceptors = () => {
    this.axiosInstance.interceptors.request.use(
      (config: any) => {
        return config;
      },
      function (error) {
        // Do something with request error
        console.log("Error");
        return Promise.reject(error);
      }
    );
  };

  private setupResponseInterceptors = () => {
    this.axiosInstance.interceptors.response.use(
      (response: any) => {
        return response;
      },
      async (error) => {
        const originalRequest = error.config;
        if (error.response.status === 401 && !originalRequest._retry) {
          //this.dispatch(onLogout());
          // originalRequest._retry = true;
          // const data: any = await this.refreshToken();
          // localStorage.setItem("token", data.data.access_token);
          // localStorage.setItem("refresh_token", data.data.refresh_token);
          // originalRequest.headers["Authorization"] =
          //   "Bearer " + data.data.access_token;
          // window.location.href = "/html_pages/session_timeout.html";
          // return this.axiosInstance(originalRequest);
          return Promise.reject(error);
        }
        // Do something with request error
        return Promise.reject(error);
      }
    );
  };

  get(url: string, showLoader = true) {
    let config: any = this.getConfig();
    config.showLoader = showLoader;
    try {
      return this.axiosInstance.get(this.baseURL+url, config);
    } catch (err) {
      throw err;
    }
  }

  post(url: string, data?: any, showLoader = true) {
    let config: any = this.getConfig();
    //config.showLoader = showLoader;
    try {
      return this.axiosInstance.post(this.baseURL+url, data, config);
    } catch (err) {
      throw err;
    }
  }

  postFormData(url: string, data?: any, showLoader = true) {
    let accessToken = localStorage.getItem("token");
    let config: any = {headers: {
      'Content-Type': 'multipart/form-data',
      //'Content-Type':'application/json',
      'Authorization': "Bearer " + accessToken,
      // "x-device-time": "",
      // "x-device-game-id": "",
    }}
    //config.showLoader = showLoader;
    try {
      return this.axiosInstance.post(this.baseURL+url, data, config);
    } catch (err) {
      throw err;
    }
  }

  postIdentity(url: string, data?: any, showLoader = true) {
    let config: any = this.getConfig();
    config.showLoader = showLoader;
    var details: any = {
      username: data.username,
      password: data.password,
      grant_type: AppConfig.grantType,
    };

    var formBody: any = [];
    for (var property in details) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    return this.axiosInstance.post(url, formBody, config);
  }

  put(url: string, data?: any, showLoader = true) {
    let config: any = this.getConfig();
    config.showLoader = showLoader;
    return this.axiosInstance.put(url, data, config);
  }

  delete(url: string, data?: any, showLoader = true) {
    let config: any = this.getConfig();
    config.showLoader = showLoader;
    if (data) {
      config.data = data;
    }
    return this.axiosInstance.delete(url, config);
  }

  patch(url: string, data: any, showLoader = true) {
    let config: any = this.getConfig();
    config.showLoader = showLoader;

    return this.axiosInstance.patch(url, data, config);
  }

    getConfig(passToken=true) {
    let accessToken = localStorage.getItem("token");
    if(accessToken=="" || accessToken==null || accessToken==undefined)
      passToken=false;
    let config = passToken?{
      headers: {
        //'Content-Type': 'multipart/form-data',
        'Content-Type':'application/json',
        'Authorization': "Bearer " + accessToken,
        // "x-device-time": "",
        // "x-device-game-id": "",
      },
    }:{
      headers: {
        //'Content-Type': 'multipart/form-data',
        'Content-Type':'application/json',
        'Authorization': "Bearer " + accessToken,
        // "x-device-time": "",
        // "x-device-game-id": "",
      },
    };

    return config;
  }

  
  refreshToken() {
    let config: any = this.getConfig();
    let refreshToken = localStorage.getItem("refresh_token");
    var details: any = {
      username: "",
      password: "",
      grant_type: AppConfig.refreshTokenGrantType,
      refresh_token: refreshToken,
    };

    var formBody: any = [];
    for (var property in details) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    return this.axiosInstance.post(
      this.IdentityURL + "/connect/token",
      formBody,
      config
    );
  }
}

export default new ApiService();