import React, { useEffect, useState } from "react";
import { useRef } from "react";
import FloatingLabelTextInput from "../../components/floatingtextinput.tsx";
import Joi, { ValidationErrorItem } from "joi";
import { connect } from "react-redux";
import { fetchServiceById, saveService, updateService } from "../../actions/serviceActionCreator.ts";
import { json, useLocation, useNavigate } from "react-router-dom";
import { Service } from "../../models/index.ts";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { saveEmployee } from "../../actions/employeeActionCreator.ts";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { FileUploader } from "react-drag-drop-files";

const fileTypes = ["JPG", "PNG"];

interface EmployeeCreateProps {
    saveEmployee: (data: any, callback: any) => void;
    isAuthenticated:boolean;
    role:string;
}
const EmployeeCreate = (props: EmployeeCreateProps) => {
    let navigate = useNavigate();
    const { state } = useLocation();

    const [errors, setErrors] = useState<any>(undefined);
    const [heading, setHeading] = useState("Create Employee");
    const [buttonText, setButtonText] = useState("Save");
    const [joined, setJoined] = useState(new Date());
    var intialDetails = {
        name: "",
        email: "",
        phone: "",
        center:0
    }
    const [employeeDetails, setEmployeeDetails] = useState(
        intialDetails
    );
    const [file, setFile] = useState(null);
  const handleChange = (file) => {
    setFile(file);
  };

  useEffect(()=>{
    if(!props.isAuthenticated){
        navigate("/login");
    }
    else if(!props.role?.includes("Admin")){
        navigate("/");
    }
},[]);
    // useEffect(() => {
    //     if (state?.id != undefined) {
    //         props.fetchEmployee(state.id);
    //         setHeading("Update Service");
    //         setButtonText("Update");
    //     }
    // }, [])
    // useEffect(() => {
    //     if (state?.id != undefined && props?.service != undefined) {
    //         let customFields: any = [];
    //         var editDetails = {
    //             name: props?.service.name,
    //             description: props?.service?.description,
    //             fees: props?.service?.fees,
    //             serviceCharge: props?.service?.serviceCharge
    //         };
    //         props?.service?.customFields.forEach(element => {
    //             let obj = {
    //                 "Name": element.name,
    //                 "Type": element.customFieldType,
    //                 "IsMandatory": element.isMandatory
    //             };
    //             customFields.push(obj);
    //         });
    //         setRows(customFields);
    //         setServiceDetails(editDetails);
    //     }
    // }, [props?.service])
    const onChange = (
        event:
            | React.ChangeEvent<HTMLInputElement>
            | React.ChangeEvent<HTMLSelectElement>
    ) => {
        const _guardianDetails = {
            ...JSON.parse(JSON.stringify(employeeDetails)),
            [event.target.name]: event.target.value,
        };
        setEmployeeDetails(_guardianDetails);
        validateFields(_guardianDetails);
    };


    const schema = Joi.object().keys({
        name: Joi.string().required().label("Enter Name"),
        email: Joi.string()
            .required()
            .email({ tlds: { allow: false } })
            .label("Enter Valid Email"),
        phone: Joi.string()
            .required()
            .label("Enter Valid Phone number"),
            center: Joi.number()
            .required()
            .label("Enter Valid center"),
    });
    const validateFields = (requestDetails: any) => {
        setErrors(undefined);
        const result = schema.validate(requestDetails, {
            abortEarly: false,
            allowUnknown: true,
        });
        result.error &&
            result.error.details &&
            setErrorFromJoi(result.error.details);
    };
    const setErrorFromJoi = (validationErrors: Array<ValidationErrorItem>) => {
        let _erroObject = {};
        validationErrors.map((errorItem) => {
            if (errorItem.context && errorItem.context.key) {
                _erroObject = {
                    ..._erroObject,
                    [errorItem.context.key]: errorItem.context.label,
                };
            }
        });
        setErrors(_erroObject);
    };
    const onSubmit = async () => {
        const result = schema.validate(employeeDetails, {
            abortEarly: false,
            allowUnknown: false,
        });
        if (result.error == undefined) {
            if (state?.id == undefined) {
                const formData=new FormData();
                formData.append("Name",employeeDetails.name);
                formData.append("Email",employeeDetails.email);
                formData.append("Phone",employeeDetails.phone);
                formData.append("Center",JSON.parse(JSON.stringify(employeeDetails.center)));
                formData.append("Joined",JSON.parse(JSON.stringify(joined)));
                if(file!=null){
                formData.append("Photo", file);
                }
                let request = {
                    "Name": employeeDetails.name,
                    "Email": employeeDetails.email,
                    "Phone": employeeDetails.phone,
                    "Joined": joined,
                    "Photo":file
                };
                await props.saveEmployee(
                    formData,
                    callback
                );
            }
            else {
                let request = {
                    //"Id": state.id,
                    "Name": employeeDetails.name,
                    "Email": employeeDetails.email,
                    "Phone": employeeDetails.phone,
                    "Joined": joined,
                    //"CustomFields": customFields
                };
                // await props.updateService(
                //     request,
                //     callback
                // );
            }
        } else {
            result.error &&
                result.error.details &&
                setErrorFromJoi(result.error.details);
        }
    };

    const callback = (status) => {
        toast("Saved Successfully");
        navigate("/employee-index");
    }
    
    return (
        <>
            <div className="studdash mt-4">
                <div className="container">
                    <div className="card">
                        <h2>{heading}</h2>

                        <div className="main-form row">
                            <div className="controls formgroup col-md-6">
                                <FloatingLabelTextInput
                                    isMandatory
                                    name={"name"}
                                    label={"Name"}
                                    onChange={onChange}
                                    value={employeeDetails.name}
                                    isNumber={false}
                                />
                                {errors && errors.name && (
                                    <div className="errors">{errors.name}</div>
                                )}
                            </div>
                            <div className="controls formgroup col-md-6">
                                <FloatingLabelTextInput
                                    isMandatory
                                    name={"email"}
                                    label={"Email"}
                                    onChange={onChange}
                                    value={employeeDetails.email}
                                    isNumber={false}
                                />
                                {errors && errors.email && (
                                    <div className="errors">{errors.email}</div>
                                )}
                            </div>
                            <div className="controls formgroup col-md-6">
                                <FloatingLabelTextInput
                                    isMandatory
                                    name={"phone"}
                                    label={"Phone"}
                                    onChange={onChange}
                                    value={employeeDetails.phone}
                                    isNumber={true}
                                />
                                {errors && errors.phone && (
                                    <div className="errors">{errors.phone}</div>
                                )}
                            </div>
                            <div className="controls formgroup col-md-6">
                                    <select name="center" className="form-control"
                                        onChange={onChange}
                                        value={employeeDetails.center}>
                                        <option value={0}>Thodannur</option>
                                        <option value={1}>Cherandathur</option>
                                        <option value={2}>Thurasheri Kadav</option>
                                    </select>
                                    <label className={'active'}>
                                        Center
                                        <i>*</i>
                                    </label>
                                </div>
                            <div className="controls formgroup col-md-6">
                                <DatePicker selected={joined} onChange={(date) => setJoined(date)} />
                                <label className='active'>
                                    Joined Date
                                </label>
                            </div>
                            <FileUploader handleChange={handleChange} name="file" types={fileTypes} />
                            <div className="btnwrap">
                                <button
                                    className="btn"
                                    onClick={() => navigate("/employee-index")}
                                >
                                    {"Cancel"}
                                </button>
                                <button
                                        className="btn"
                                        onClick={() => onSubmit()}
                                    >
                                        {buttonText}
                                    </button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const mapDispatchToProps = {
    saveEmployee: (data: any, callback: any) => saveEmployee(data, callback),
}

function mapStateToProps(state: any) {
    return {
        isAuthenticated: state.auth.isAuthenticated,
        service: state.service.service,
        role:state.auth.role
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(EmployeeCreate);